import { Alert, Card, CardContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApiErrorResponse } from 'services/responses';
import { DeviceInfo } from '../../../../models';
import { useDialogs } from '../../../../providers';
import apiServices from '../../../../services';
import { useAppSelector } from '../../../../store';
import { selectCurrentDeviceSession } from '../../../../store/features/device.slice';
import { Page, UserDevices } from '../../shared';

export const MyDevicesPage = () => {
  const { dialog } = useDialogs();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { deviceId } = useAppSelector(selectCurrentDeviceSession);
  const { data, isLoading } = apiServices.me.devices.useGetMyDevicesQuery();
  const [unlinkDevice, { isLoading: isUnlinking }] =
    apiServices.me.devices.useUnlinkMyDeviceMutation();

  const handleUnlinkClick = (device: DeviceInfo) => {
    dialog.confirm({
      title: <FormattedMessage id={'global.user.text.confirmUnlinkDeviceTitle'} />,
      message: <FormattedMessage id={'global.user.text.confirmUnlinkDevice'} />,
      onOk: () => {
        unlinkDevice({ deviceCode: device.deviceCode })
          .unwrap()
          .then(() =>
            enqueueSnackbar(formatMessage({ id: 'global.user.notifications.deviceUnlinked' }), {
              variant: 'success',
            }),
          )
          .catch((e: ApiErrorResponse) => {
            enqueueSnackbar(e.error, { variant: 'error' });
          });
      },
    });
  };

  return (
    <Page
      goBackProps={{ show: true, destination: 'back' }}
      maxWidth={'sm'}
      loading={isLoading}
      title={formatMessage({ id: 'user.pages.devices' })}
    >
      {!!data && (
        <Card>
          <CardContent>
            <Alert severity='info'>
              <FormattedMessage id='user.text.devicesInfoMessage' />
            </Alert>
            <UserDevices
              currentDeviceCode={deviceId}
              devices={data}
              onUnlinkClick={handleUnlinkClick}
            />
          </CardContent>
        </Card>
      )}
    </Page>
  );
};
