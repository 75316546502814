import {useNavigate, useParams} from 'react-router-dom';
import apiServices from '../../../../services';
import {Page, UserCard} from '../../shared';

import {UserRole} from 'enums';
import {useCurrentUser} from 'hooks';
import {useSnackbar} from 'notistack';
import {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {UserInfo} from '../../../../models';
import {useDialogs} from '../../../../providers';
import {getRoute} from '../../../../utils';
import {adminRouteNames} from '../index';
import {NoItemsExists} from "../../../../components";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query";

export const UserViewPage = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {dialog} = useDialogs();
    const navigate = useNavigate();
    const {user: currentUser} = useCurrentUser();
    const {formatMessage} = useIntl();
    const {userId} = useParams();
    const {
        data: user,
        isLoading,
        error,
    } = apiServices.admin.users.useGetUserByIdQuery({
        userId: userId || '',
    });
    const [deleteUser, {isLoading: isDeleting}] = apiServices.admin.users.useDeleteUserMutation();

    const {data: devices, isLoading: isDevicesLoading} =
        apiServices.admin.devices.useGetDeviceByUserIdQuery({userId: userId || ''});

    const canEdit = useMemo(() => {
        return [UserRole.Admin, UserRole.Developer].some((x) => x === currentUser?.role.name);
    }, [currentUser]);

    const canDelete = useMemo(() => {
        return [UserRole.Admin, UserRole.Developer].some((x) => x === currentUser?.role.name);
    }, [currentUser]);

    const userNotFound = useMemo(() => {
        return (error as FetchBaseQueryError)?.status === 404;
    }, [error]);

    const onDelete = async (user: UserInfo) => {
        dialog.confirm({
            onOk: async () => await handleDeleteUser(user),
            okButtonProps: {color: 'error'},
            okText: <FormattedMessage id={'global.buttons.delete'}/>,
            title: <FormattedMessage id={'admin.userPage.text.confirmDeleteSingleUserTitle'}/>,
            message: <FormattedMessage id={'admin.userPage.text.confirmDeleteSingleUser'}/>,
        });
    };

    const handleDeleteUser = async (user: UserInfo) => {
        deleteUser({userId: user.id})
            .unwrap()
            .then(() => {
                enqueueSnackbar(
                    formatMessage(
                        {id: 'admin.userPage.notifications.userDeleted'},
                        {
                            firstName: user.firstName,
                            lastName: user.lastName,
                        },
                    ),
                    {variant: 'success'},
                );
                navigate(-1);
            })
            .catch((e) => {
                console.log('err');
                const message = e?.error || formatMessage({id: 'global.errors.common'});
                enqueueSnackbar(message, {variant: 'error'});
            });
    };

    const onEdit = (user: UserInfo) => {
        navigate(
            getRoute(adminRouteNames.management.root, adminRouteNames.management.users.edit(user.id)),
        );
    };

    const onDeviceView = () => {
        navigate(
            getRoute(adminRouteNames.management.root, adminRouteNames.management.users.devices(user?.id)),
        );
    };

    return (
        <Page
            goBackProps={{show: true, destination: 'back'}}
            maxWidth={'md'}
            loading={isLoading || isDevicesLoading}
            title={formatMessage({id: 'admin.pages.userProfile'})}
        >
            {userNotFound && (<NoItemsExists title={<FormattedMessage id='admin.userPage.labels.userNotFound'/>}
                                             description={<FormattedMessage
                                                 id='admin.userPage.labels.userDetailsNotFound'/>}/>)}
            {!userNotFound && !!user && (
                <UserCard
                    user={user}
                    devices={devices || []}
                    onDevicesView={onDeviceView}
                    canEdit={canEdit || user.id === currentUser?.id}
                    onEdit={onEdit}
                    canDelete={canDelete}
                    onDelete={onDelete}
                    deleting={isDeleting}
                />
            )}
        </Page>
    );
};
