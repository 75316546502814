import {Column, DataTable} from "../../../../../../components";
import {AuditLog} from "../../../../../../models";
import {FormattedDate, FormattedMessage} from "react-intl";
import {Box, Chip, Link, Typography} from "@mui/material";
import {AuditType} from "../../../../../../enums";
import {Link as RouterLink} from "react-router-dom";
import {getRoute} from "../../../../../../utils";
import {adminRouteNames} from "../../../index";
import React from "react";

type AuditLogsDataTableProps = {
    items: AuditLog[];
    totalCount: number;
    filteredCount: number;
    loading?: boolean;
    page: number;
    pageSize: number;
    onPageChange?: (newPage: number) => void;
    onPageSizeChange?: (newSize: number) => void;
};
export const AuditLogsDataTable = ({
                                       items,
                                       page,
                                       pageSize,
                                       onPageSizeChange,
                                       onPageChange,
                                       filteredCount,
                                       loading
                                   }: AuditLogsDataTableProps) => {

    const getAuditTypeColor = (auditType: AuditType): 'warning' | 'error' | 'info' => {
        switch (auditType) {
            case AuditType.Modified:
                return 'warning'
            case AuditType.Deleted:
                return 'error';
            case AuditType.Added:
                return 'info';
        }
    }

    const columns: Column<AuditLog>[] = [
        {
            key: 'modifiedBy',
            dataIndex: 'email',
            align: 'left',
            width: 150,
            title: <FormattedMessage id='admin.auditLogs.labels.modifiedBy'/>,
            render: (value, record) => {
                return (record.userId !== 'System' ? <Link
                    component={RouterLink}
                    to={getRoute(
                        adminRouteNames.management.root,
                        adminRouteNames.management.users.view(record.userId),
                    )}
                >
                    {value || 'N/A'}
                </Link> : record.userId)
            }
        },
        {
            key: 'tableName',
            dataIndex: 'tableName',
            align: 'left',
            width: 150,
            title: <FormattedMessage id='admin.auditLogs.labels.entity'/>,
            render: (value) => <Chip label={value} variant='outlined'
                                     sx={{width: '100%'}}/>
        },
        {
            key: 'keyValues',
            dataIndex: 'keyValues',
            align: 'left',
            title: <FormattedMessage id='admin.auditLogs.labels.entityKey'/>,
            render: (value) => value.Id.Value
        },

        {
            key: 'auditType',
            dataIndex: 'auditType',
            align: 'center',
            width: 150,
            title: <FormattedMessage id='admin.auditLogs.labels.auditType'/>,
            render: (value) => <Chip label={value} color={getAuditTypeColor(value)} variant='outlined'
                                     sx={{width: '100%'}}/>
        },
        {
            key: 'dateTime',
            dataIndex: 'dateTime',
            align: 'left',
            width: 350,
            title: <FormattedMessage id='admin.auditLogs.labels.logTime'/>,
            render: (value: Date) => <Box>
                <Typography variant='body2'>
                    <FormattedDate value={value} dateStyle="long"/>
                </Typography>
                <Typography variant='caption'>
                    <FormattedDate value={value} timeStyle="medium"/>
                </Typography>
            </Box>
        }
    ];

    return (
        <DataTable columns={columns}
                   rowKey={record => record.id}
                   data={items}
                   totalCount={filteredCount}
                   loading={loading}
                   page={page}
                   pageSize={pageSize}
                   onPageChange={(newPage) => onPageChange?.(newPage)}
                   onPageSizeChange={(newSize) => onPageSizeChange?.(newSize)}/>
    )
}