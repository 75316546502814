import { identityApi } from './http.service';
import { DeviceInfo, FilteredResult, UserInfo, UserPaginationFilter, UserRequestMessage } from '../models';
import { baseErrorResponseTransform } from './queries';
import {
  CreateUserRequest,
  DeleteUserRequest,
  FilterUsersRequest,
  GetUserByIdRequest,
  GetUserRequestMessage,
  ResendInvitationEmailRequest,
  UpdateUserRequest,
} from './requests';

const baseAdminUrl = 'administration/users';

const adminUsersService = identityApi.injectEndpoints({
  endpoints: (builder) => ({
    filterUsers: builder.query<FilteredResult<UserInfo>, FilterUsersRequest>({
      query: (filter) => ({
        url: `${baseAdminUrl}/filter`,
        method: 'GET',
        params: filter,
      }),
      transformErrorResponse: baseErrorResponseTransform,
      providesTags: [{ type: 'Users', id: 'filter' }],
    }),
    deleteUser: builder.mutation<void, DeleteUserRequest>({
      query: ({ userId }) => ({
        url: `${baseAdminUrl}/${userId}`,
        method: 'DELETE',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: [{ type: 'Users', id: 'filter' }],
    }),
    getUserById: builder.query<UserInfo, GetUserByIdRequest>({
      query: ({ userId }) => ({
        url: `${baseAdminUrl}/${userId}`,
        method: 'GET',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg.userId }],
    }),
    getUserRequestMessage: builder.query<UserRequestMessage, GetUserRequestMessage>({
      query: ({ userId }) => ({
        url: `${baseAdminUrl}/${userId}/request-message`,
        method: 'GET',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      providesTags: (result, error, arg) => [{ type: 'Users', id: `${arg.userId}/message` }],
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: (user) => ({
        url: `${baseAdminUrl}/${user.id}`,
        method: 'PUT',
        body: user,
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: (result, error, arg) => [
        { type: 'Users', id: 'filter' },
        { type: 'Users', id: arg.id },
      ],
    }),
    createInactiveUser: builder.mutation<void, CreateUserRequest>({
      query: (user) => ({
        url: baseAdminUrl,
        method: 'POST',
        body: user,
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: [{ type: 'Users', id: 'filter' }],
    }),
    resendInvitationEmail: builder.mutation<void, ResendInvitationEmailRequest>({
      query: ({ userId }) => ({
        url: `${baseAdminUrl}/${userId}/resend-invitation`,
        method: 'POST',
        body: {},
      }),
      transformErrorResponse: baseErrorResponseTransform,
    }),
  }),
});

export const {
  useFilterUsersQuery,
  useDeleteUserMutation,
  useGetUserByIdQuery,
  useGetUserRequestMessageQuery,
  useUpdateUserMutation,
  useCreateInactiveUserMutation,
  useResendInvitationEmailMutation,
  endpoints,
} = adminUsersService;
