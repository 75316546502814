import { DeleteForever, Edit, Mail } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  Link,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Order } from 'components/dataTable/DataTableHeader';
import { UserRole } from 'enums';
import { useCurrentUser, useUsersFilter } from 'hooks';
import { useDialogs } from 'providers';
import { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import apiServices from 'services';
import { useAppDispatch, useAppSelector } from 'store';
import { getRoute } from 'utils';
import { Column, DataTable } from '../../../../../components';
import { UserInfo } from '../../../../../models';
import { adminRouteNames } from '../../index';
import React from 'react';
import { enqueueSnackbar } from 'notistack';

const CanEditRoles = [UserRole.Admin, UserRole.Developer];

type UsersDataTableProps = {
  users: UserInfo[];
  totalCount: number;
  filteredCount: number;
  loading?: boolean;
};

export const UsersDataTable = ({ users, filteredCount, loading = false }: UsersDataTableProps) => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const { dialog } = useDialogs();
  const { formatMessage } = useIntl();
  const { filter, setFilter } = useUsersFilter();

  const [checkedUsers, setCheckedUsers] = useState<readonly string[]>([]);
  const [userId, setUserId] = useState<string>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [deleteUser] = apiServices.admin.users.useDeleteUserMutation();
  const {
    data: requestMessage,
    isLoading,
    isFetching,
  } = apiServices.admin.users.useGetUserRequestMessageQuery(
    { userId: userId || '' },
    { skip: !userId },
  );

  const handleEditClick = (item: UserInfo) => {
    navigate(
      getRoute(adminRouteNames.management.root, adminRouteNames.management.users.edit(item.id)),
    );
  };

  const handleSingleDeleteClick = (item: UserInfo) => {
    dialog.confirm({
      onOk: () => handleDeleteUser([item.id]),
      okButtonProps: { color: 'error' },
      okText: <FormattedMessage id={'admin.userPage.buttons.delete'} />,
      title: <FormattedMessage id={'admin.userPage.text.confirmDeleteSingleUserTitle'} />,
      message: <FormattedMessage id={'admin.userPage.text.confirmDeleteSingleUser'} />,
    });
  };

  const handleMultipleDeleteClick = () => {
    dialog.confirm({
      onOk: () => {
        handleDeleteUser(checkedUsers).then(() => setCheckedUsers([]));
      },
      okButtonProps: { color: 'error' },
      okText: <FormattedMessage id={'admin.userPage.buttons.delete'} />,
      title: (
        <FormattedMessage
          id={'admin.userPage.text.confirmDeleteMultipleUserTitle'}
          values={{ count: checkedUsers.length }}
        />
      ),
      message: (
        <FormattedMessage
          id={'admin.userPage.text.confirmDeleteMultipleUser'}
          values={{ count: checkedUsers.length }}
        />
      ),
    });
  };

  const handleDeleteUser = async (userIds: readonly string[]) => {
    userIds.map(async (id) => {
      await deleteUser({ userId: id });
    });

    setCheckedUsers([]);
    setFilter({ ...filter, page: filter.page > 1 ? filter.page - 1 : 1 });
    enqueueSnackbar(
      formatMessage({ id: 'admin.userPage.notifications.usersDeleted' }, { count: userIds.length }),
      { variant: 'success' },
    );
  };

  const showRequestMessage = (event: React.MouseEvent<HTMLButtonElement>, user: UserInfo) => {
    setAnchorEl(event.currentTarget);
    setUserId(user.id);
  };

  const viewOnlyColumns: Column<UserInfo>[] = [
    {
      key: 'firstName',
      dataIndex: 'fullName',
      sortable: true,
      title: <FormattedMessage id={'admin.userPage.labels.fullName'} />,
      align: 'left',
      render: (value, record) => (
        <Link
          component={RouterLink}
          to={getRoute(
            adminRouteNames.management.root,
            adminRouteNames.management.users.view(record.id),
          )}
        >
          {value}
        </Link>
      ),
    },
    {
      key: 'email',
      dataIndex: 'email',
      sortable: true,
      title: <FormattedMessage id={'admin.userPage.labels.email'} />,
      align: 'left',
    },

    {
      key: 'jobTitle',
      dataIndex: 'jobTitle',
      sortable: true,
      width: 150,
      title: <FormattedMessage id={'admin.userPage.labels.jobTitle'} />,
      align: 'left',
    },
    {
      key: 'degree',
      dataIndex: 'degree',
      sortable: true,
      title: <FormattedMessage id={'admin.userPage.labels.degree'} />,
      align: 'left',
    },
    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: <FormattedMessage id={'admin.userPage.labels.status'} />,
      align: 'center',
      width: 80,
      render: (value: boolean) => (
        <Chip
          sx={{ minWidth: '100%' }}
          variant='outlined'
          color={value ? 'success' : 'error'}
          label={
            <FormattedMessage
              id={value ? 'admin.userPage.labels.active' : 'admin.userPage.labels.inactive'}
            />
          }
        />
      ),
    },
    {
      key: 'hasRequest',
      dataIndex: 'hasRequest',
      title: <FormattedMessage id={'admin.userPage.labels.message'} />,
      align: 'center',
      width: 80,
      render: (value: boolean, record) => (
        <IconButton onClick={(e) => showRequestMessage(e, record)} disabled={!value}>
          <Badge color='success' variant='dot' invisible={!value}>
            <Mail />
          </Badge>
        </IconButton>
      ),
    },
    {
      key: 'createdDate',
      dataIndex: 'createdDate',
      sortable: true,
      title: <FormattedMessage id={'admin.userPage.labels.createdDate'} />,
      align: 'left',
      render: (value: Date) => <FormattedDate dateStyle='medium' timeStyle='short' value={value} />,
    },
  ];

  const adminColumns: Column<UserInfo>[] = [
    ...viewOnlyColumns,
    {
      key: 'role',
      dataIndex: 'role',
      sortable: true,
      title: <FormattedMessage id={'admin.userPage.labels.role'} />,
      align: 'left',
      render: (_, record) => (
        <Chip sx={{ minWidth: '100%' }} variant='outlined' label={record.role.name} />
      ),
    },
    {
      key: 'actions',
      align: 'center',
      dataIndex: '',
      title: <FormattedMessage id={'admin.userPage.labels.actions'} />,
      width: '80px',
      render: (_, record) => (
        <Stack direction='row'>
          <Tooltip title={<FormattedMessage id={'admin.userPage.tooltips.edit'} />}>
            <IconButton onClick={() => handleEditClick(record)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={<FormattedMessage id={'admin.userPage.tooltips.delete'} />}>
            <IconButton onClick={() => handleSingleDeleteClick(record)} color='error'>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const toolbarActions = [
    <Tooltip title={<FormattedMessage id={'global.tooltips.deleteSelected'} />}>
      <IconButton onClick={handleMultipleDeleteClick} color='error'>
        <DeleteForever />
      </IconButton>
    </Tooltip>,
  ];

  const columns = CanEditRoles.some((x) => x === user?.role.name) ? adminColumns : viewOnlyColumns;

  return (
    <>
      <DataTable
        columns={columns}
        data={users}
        selectable={CanEditRoles.some((x) => x === user?.role.name)}
        selectedItems={checkedUsers}
        onSelectionChange={setCheckedUsers}
        totalCount={filteredCount}
        loading={loading}
        order={filter.orderDirection as Order}
        orderBy={filter.orderBy}
        onSortChanged={(order, orderBy) =>
          setFilter({
            ...filter,
            orderDirection: order,
            orderBy: orderBy,
          })
        }
        onPageChange={(page) => {
          setFilter({ ...filter, page: page });
          setCheckedUsers([]);
        }}
        onPageSizeChange={(size) =>
          setFilter({ ...filter, page: filter.page > 1 ? filter.page - 1 : 1, pageSize: size })
        }
        page={filter.page}
        pageSize={filter.pageSize}
        toolbarActions={toolbarActions}
        rowKey='id'
      />
      <Popover
        id='request-message-popover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2} maxWidth={450}>
          {(isLoading || isFetching) && <RequestMessagePreloader />}
          {!isLoading && !isFetching && <Typography>{requestMessage?.message}</Typography>}
        </Box>
      </Popover>
    </>
  );
};

const RequestMessagePreloader = () => {
  return (
    <Box sx={{ width: 200 }}>
      <Skeleton animation='pulse' />
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
    </Box>
  );
};
