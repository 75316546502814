import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteParams } from '../../../constants';
import { CompleteResetPasswordForm } from '../components/CompleteResetPasswordForm';
import { identityRouteNames } from '../index';

export const CompleteResetPasswordPage = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const resetToken = queryParams.get(RouteParams.resetPasswordToken) || '';

  const onSuccess = () => {
    navigate('/' + identityRouteNames.login);
  };

  return <CompleteResetPasswordForm resetToken={resetToken} onSuccess={onSuccess} />;
};
