import { ArrowBack } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { To, useNavigate } from 'react-router-dom';

export type GoBackButtonProps = {
  destination?: To | 'back';
  show: boolean;
};
export const GoBackButton = ({ destination, show }: GoBackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!destination) return;

    if (destination === 'back') navigate(-1);
    else navigate(destination);
  };

  if (!show) {
    return null;
  }

  return (
    <Tooltip title={<FormattedMessage id={'global.tooltips.goBack'} />}>
      <IconButton size='large' onClick={handleClick}>
        <ArrowBack />
      </IconButton>
    </Tooltip>
  );
};
