import {PaletteOptions} from "@mui/material";

const primaryPalette = {
    light: '#a6aff8',
    main: '#6073f2',
    dark: '#4058ed',
    contrastText: '#fff',
};

const secondaryPalette = {
    light: '#e6e7fd',
    main: '#909ef6',
    dark: '#3c5bdc',
    contrastText: '#000'
};

export const lightPalette: PaletteOptions = {
    mode: 'light',
    primary: primaryPalette,
    secondary: secondaryPalette,
    background:{
        default:'#F0F2FD'
    }
}