export function set(key: string, data: unknown) {
    localStorage.setItem(key, JSON.stringify(data));
}

export function get<T>(key: string): T {
    const result: string | null = window.localStorage.length > 0 ? window.localStorage.getItem(key) : null;

    return result ? JSON.parse(result) : null;
}

export function remove(key: string): void {
    window.localStorage.removeItem(key);
}

export function clear():void{
    window.localStorage.clear();
}


