import React, { createContext, useContext, useState } from 'react';
import { Modal, ModalProps } from '../components/modals';
import { TransitionProps } from '@mui/material/transitions';
import { Zoom } from '@mui/material';

type ConfirmModalProps = Omit<ModalProps, 'open' | 'onCancel'> & {
    message: React.ReactNode | string
    onCancel?: () => void;
};

type ModalType = {
    confirm: (modalOptions: ConfirmModalProps) => void;
}

interface DialogContext {
    dialog: ModalType;
}

const DialogContext = createContext<DialogContext>({
    dialog: {
        confirm: () => {
        }
    }
});

export const useDialogs = () => {
    const context = useContext(DialogContext);

    if (!context) {
        throw new Error('No DialogContext.Provider found when calling useDialogs.');
    }

    return context;
};

type DialogsProviderProps = {}
export const DialogsProvider = ({ children }: React.PropsWithChildren<DialogsProviderProps>) => {

    const [open, setOpen] = useState(false);

    const [modalOptions, setModalOptions] = useState<ConfirmModalProps>({
        message: <></>,
        onCancel: () => {
            closeModel();
        }
    });


    const closeModel = () => setOpen(false);

    const dialog: ModalType = {
        confirm(modalOptions) {
            setOpen(true);
            setModalOptions({
                ...modalOptions, onOk: () => {
                    modalOptions.onOk?.();
                    closeModel();
                }, onCancel: () => {
                    modalOptions.onCancel?.();
                    closeModel();
                }
            });
        }
    };


    return (
        <DialogContext.Provider value={{
            dialog: dialog
        }}>
            {children}
            <Modal open={open}
                onCancel={modalOptions.onCancel!}
                TransitionComponent={Transition}
                {...modalOptions}>
                {modalOptions.message}
            </Modal>
        </DialogContext.Provider>)
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom unmountOnExit ref={ref} {...props} />;
});