import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { toggleMenu, selectAppState } from 'store/features/app.slice';

export const useAppState = () => {
  const { menuCollapsed } = useAppSelector(selectAppState);
  const dispatch = useDispatch();

  return useMemo(() => {
    return {
      menuCollapsed: menuCollapsed,
      toggleMenu: () => {
        dispatch(toggleMenu());
      },
    };
  }, [menuCollapsed]);
};
