import { DownloadOutlined } from '@mui/icons-material';
import { Button, Link, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Title } from '../layout';
import { downloadAppUrl } from 'env.constants';

export const DownloadApp = () => {
  return (
    <Stack spacing={6} justifyItems='center'>
      <Title>
        <FormattedMessage id='identity.downloadPage.title' />
      </Title>
      <Typography>
        <FormattedMessage id='identity.downloadPage.messages.success' />
      </Typography>
      <Button
        target='_blank'
        href={downloadAppUrl}
        variant='contained'
        size='large'
        endIcon={<DownloadOutlined />}
      >
        <FormattedMessage id='identity.downloadPage.buttons.download' />
      </Button>
      <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Typography variant='body2'>
          <FormattedMessage id='identity.downloadPage.messages.requirements' />
        </Typography>
        <Link href='https://bodyscope.health/#sys-requirements' target='_blank'>
          <FormattedMessage id='identity.downloadPage.links.seeRequirements' />
        </Link>
      </Stack>
    </Stack>
  );
};
