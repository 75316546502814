import { z } from 'zod';

export const SingInSchema = z.object({
  email: z
    .string()
    .nonempty({ message: 'global.forms.errors.email.required' })
    .email({ message: 'global.forms.errors.email.invalidFormat' }),
  password: z.string().nonempty({ message: 'global.forms.errors.password.required' }),
});

export const RequestResetPasswordSchema = z.object({
  email: z
    .string()
    .nonempty({ message: 'global.forms.errors.email.required' })
    .email({ message: 'global.forms.errors.email.invalidFormat' }),
});

export const CompleteResetPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .nonempty({ message: 'global.forms.errors.password.required' })
      .min(8, { message: 'global.forms.errors.password.minLength', })
      .max(32, { message: 'global.forms.errors.password.maxLength' })
      .regex(/\d/g, { message: 'global.forms.errors.password.numberRequired' })
      .regex(/[A-Z]+/g, { message: 'global.forms.errors.password.capitalLetterRequired' })
      .regex(/[!@#$%^&*]+/g, { message: 'global.forms.errors.password.symbolRequired' }),
    confirmPassword: z.string().nonempty({ message: 'global.forms.errors.password.required' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'global.forms.errors.password.confirmEqual',
    path: ['confirmPassword'],
  });

export const ChangePasswordSchema = z
  .object({
    currentPassword: z.string().nonempty({ message: 'global.forms.errors.password.required' }),
    newPassword: z
      .string()
      .nonempty({ message: 'global.forms.errors.password.required' })
      .min(8, { message: 'global.forms.errors.password.minLength' })
      .max(32, { message: 'global.forms.errors.password.maxLength' })
      .regex(/\d/g, { message: 'global.forms.errors.password.numberRequired' })
      .regex(/[A-Z]+/g, { message: 'global.forms.errors.password.capitalLetterRequired' })
      .regex(/[!@#$%^&*]+/g, { message: 'global.forms.errors.password.symbolRequired' }),
    confirmPassword: z.string().nonempty({ message: 'global.forms.errors.password.required' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'global.forms.errors.password.confirmEqual',
    path: ['confirmPassword'],
  });

export const CompleteInvitationSchema = CompleteResetPasswordSchema;
