import { Box, Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotFoundSvg } from '../../assets/404_page_v2.svg';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const goToMain = () => navigate('/');

  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
      <Stack spacing={2} alignItems='center' m={2}>
        <NotFoundSvg height={550} />
        <Button size='large' variant='outlined' color='secondary' fullWidth onClick={goToMain}>
          <FormattedMessage id='global.buttons.goToMain' />
        </Button>
      </Stack>
    </Box>
  );
};
