import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Zoom } from '@mui/material';

export const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom unmountOnExit ref={ref} {...props} />;
});
