import { MainLogo, MainMenu, UserProfile } from './shared';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, CircularProgress, Grid, IconButton } from '@mui/material';
import { Outlet } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { adminMenuItems } from './shared/components/mainMenu/items';
import apiServices from 'services';

import { UserRole } from 'enums';
import { useState } from 'react';
import { useAppState } from 'hooks';

export const MainLayout = () => {
  // const [open, setOpen] = useState(true);
  const { menuCollapsed, toggleMenu } = useAppState();
  const { data: user, isLoading } = apiServices.me.users.useGetMyUserQuery();

  // const toggleDrawerOpen = () => {
  //   setOpen((prevState) => !prevState);
  // };

  if (isLoading) {
    return (
      <Grid container justifyContent='center' alignContent='center' sx={{ minHeight: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {user?.role.name !== UserRole.Default && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => toggleMenu()}
              edge='start'
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div style={{ flexGrow: 1 }}>
            <MainLogo />
          </div>
          <UserProfile user={user!} />
        </Toolbar>
      </AppBar>
      {user?.role.name !== UserRole.Default && (
        <MainMenu items={adminMenuItems} collapsed={!menuCollapsed} />
      )}
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};
