import {AuditLogsFilter} from "../../models";
import {MIN_ROWS_PER_PAGE} from "../../utils/constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";


const initialState: AuditLogsFilter = {
    page: 1,
    pageSize: MIN_ROWS_PER_PAGE,
    entity: 'm'
};

export const auditLogs = createSlice({
    name: 'auditLogs',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<AuditLogsFilter>) => {
            return {...state, ...action.payload};
        },
        resetFilter: (state) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {resetFilter, setFilter} = auditLogs.actions;
export const selectAuditLogsFilter = (state: RootState) => state.auditLogs;

export default auditLogs.reducer;
