import {Page} from '../../shared';
import {FormattedMessage, useIntl} from 'react-intl';
import {useCurrentUser} from '../../../../hooks';
import apiServices from '../../../../services';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {Card, CardActions, CardContent, Stack} from '@mui/material';
import {Form, PasswordField, Button, getFormErrorMessage, PasswordRequirements} from '../../../../components';
import {useForm} from 'react-hook-form';
import {ChangePasswordRequest} from '../../../../services/requests';
import {ApiErrorResponse} from '../../../../services/responses';
import {setBackendError} from '../../../../utils';
import {zodResolver} from '@hookform/resolvers/zod';
import {ChangePasswordSchema} from 'models/schemas';

export const ChangePasswordPage = () => {
    const {user} = useCurrentUser();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {formatMessage} = useIntl();
    const [changePassword, {isLoading}] = apiServices.me.users.useChangeMyPasswordMutation();
    const {
        register,
        setError,
        trigger,
        handleSubmit,
        formState: {errors, dirtyFields},
    } = useForm<ChangePasswordRequest>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        resolver: zodResolver(ChangePasswordSchema),
    });

    const handleCancel = () => navigate(-1);

    const onSubmit = (data: ChangePasswordRequest) => {
        changePassword(data)
            .unwrap()
            .then(() => {
                enqueueSnackbar(formatMessage({id: 'user.notifications.passwordChanged'}), {
                    variant: 'success',
                });
                navigate(-1);
            })
            .catch((e: ApiErrorResponse) => {
                setBackendError(setError, e);
            });
    };

    return (
        <Page
            goBackProps={{show: true, destination: 'back'}}
            maxWidth='sm'
            title={formatMessage({id: 'user.pages.changePassword'})}
        >
            {user && (
                <Card>
                    <CardContent>
                        <Form formName='changePassword' onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                <PasswordField
                                    error={!!errors.currentPassword}
                                    helperText={getFormErrorMessage(errors.currentPassword?.message)}
                                    autoComplete='current-password'
                                    label={formatMessage({id: 'user.labels.currentPassword'})}
                                    {...register('currentPassword')}
                                />

                                <PasswordField
                                    error={!!errors.newPassword}
                                    helperText={getFormErrorMessage(errors.newPassword?.message)}
                                    autoComplete='new-password'
                                    label={formatMessage({id: 'user.labels.newPassword'})}
                                    {...register('newPassword', {
                                        onChange: async (e) => {
                                            if (e.target.value && dirtyFields.confirmPassword) {
                                                await trigger(['confirmPassword'])
                                            }
                                        },
                                    })}
                                />
                                <PasswordField
                                    error={!!errors.confirmPassword}
                                    helperText={getFormErrorMessage(errors.confirmPassword?.message)}
                                    autoComplete='new-password'
                                    label={formatMessage({id: 'user.labels.confirmPassword'})}
                                    {...register('confirmPassword', {
                                        onChange: async (e) => {
                                            if (e.target.value && dirtyFields.newPassword) {
                                                await trigger(['newPassword']);
                                            }
                                        }
                                    })}
                                />
                                <PasswordRequirements errors={errors.newPassword?.types}
                                                      touched={dirtyFields.newPassword}/>

                            </Stack>
                        </Form>
                    </CardContent>
                    <CardActions sx={{m: 1}}>
                        <Button disabled={isLoading} fullWidth color='inherit' onClick={handleCancel}>
                            <FormattedMessage id={'global.buttons.cancel'}/>
                        </Button>
                        <Button
                            loading={isLoading}
                            fullWidth
                            type='submit'
                            form='changePassword'
                            variant='contained'
                        >
                            <FormattedMessage id={'global.buttons.save'}/>
                        </Button>
                    </CardActions>
                </Card>
            )}
        </Page>
    );
};
