import {ForwardToInbox} from '@mui/icons-material';
import {Alert, TextField, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import apiServices from '../../../services';

import {zodResolver} from '@hookform/resolvers/zod';
import {RequestResetPasswordData} from 'models';
import {RequestResetPasswordSchema} from 'models/schemas';
import {useState} from 'react';
import {ApiErrorResponse} from '../../../services/responses';
import {setBackendError} from '../../../utils';
import {EmailSentResult} from './EmailSentResult';
import {IdentityForm} from './IdentityForm';
import {getFormErrorMessage} from 'components';

type ResetPasswordFormProps = {
    onSuccess?: () => void;
};

export const ResetPasswordForm = ({onSuccess}: ResetPasswordFormProps) => {
    const {formatMessage} = useIntl();
    const {
        register,
        setError,
        handleSubmit,
        getValues,
        formState: {errors, dirtyFields},
    } = useForm<RequestResetPasswordData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(RequestResetPasswordSchema),
    });
    const [resetPassword, {isLoading}] = apiServices.identity.useResetPasswordMutation();
    const [retries, setRetries] = useState(0);

    const handleSubmitForm = (data: RequestResetPasswordData) => {
        resetPassword({
            email: data.email,
        })
            .unwrap()
            .then(() => {
                setRetries((prevState) => prevState + 1);
                onSuccess?.();
            })
            .catch((e: ApiErrorResponse) => {
                setBackendError(setError, e);
            });
    };
    const handleSendAgainBtn = () => {
        setRetries((prevState) => prevState + 1);
        const value = getValues('email');
        handleSubmitForm({email: value});
    };

    if (retries >= 1) {
        return <EmailSentResult loading={isLoading} onResendClick={handleSendAgainBtn}/>;
    }

    return (
        <IdentityForm
            title={<FormattedMessage id={'identity.resetPasswordPage.title'}/>}
            submitText={<FormattedMessage id={'identity.resetPasswordPage.buttons.sentEmail'}/>}
            loading={isLoading}
            onSubmit={handleSubmit(handleSubmitForm)}
            submitButtonProps={{
                endIcon: <ForwardToInbox/>,
            }}
        >
            <Typography>
                <FormattedMessage id={'identity.resetPasswordPage.text.info'}/>
            </Typography>
            <TextField
                type='email'
                error={!!errors.email}
                helperText={getFormErrorMessage(errors.email?.message)}
                autoComplete='username'
                label={formatMessage({id: 'identity.resetPasswordPage.labels.email'})}
                {...register('email')}
            />
        </IdentityForm>
    );
};
