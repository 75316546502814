import {Page, UserCard} from 'portals/main/shared';
import {FormattedMessage, useIntl} from 'react-intl';
import apiServices from '../../../../services';
import {useDialogs} from '../../../../providers';
import {useNavigate} from 'react-router-dom';
import {getRoute} from '../../../../utils';
import {userRouteNames} from '../index';
import {Button} from '@mui/material';
import {Shield} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import {api, identityApi} from '../../../../services/http.service';
import {ApiErrorResponse} from 'services/responses';
import {useSnackbar} from 'notistack';
import {useMemo} from "react";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {NoItemsExists} from "../../../../components";
import {logout} from 'store';

export const MyProfilePage = () => {
    const {formatMessage} = useIntl();
    const {dialog} = useDialogs();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {data: user, isLoading, error} = apiServices.me.users.useGetMyUserQuery();
    const {data: devices, isLoading: isDevicesLoading} =
        apiServices.me.devices.useGetMyDevicesQuery();
    const [deleteUser, {isLoading: isDeleting}] = apiServices.me.users.useDeleteMyUserMutation();

    const onDelete = async () => {
        dialog.confirm({
            onOk: async () => await handleDelete(),
            okButtonProps: {color: 'error'},
            okText: <FormattedMessage id={'global.buttons.delete'}/>,
            title: <FormattedMessage id={'user.text.confirmDeleteMeTitle'}/>,
            message: <FormattedMessage id={'user.text.confirmDeleteMe'}/>,
        });
    };
    const userNotFound = useMemo(() => {
        return (error as FetchBaseQueryError)?.status === 404;
    }, [error]);

    const handleDelete = async () => {
        deleteUser()
            .unwrap()
            .then(() => {
                dispatch(logout);
                dispatch(identityApi.util?.resetApiState());
                dispatch(api.util?.resetApiState());
            })
            .catch((e: ApiErrorResponse) => {
                enqueueSnackbar(e.error, {variant: 'error'});
            });
    };

    const onEdit = () => {
        navigate(getRoute(userRouteNames.profile.root, userRouteNames.profile.edit));
    };

    const onChangePassword = () => {
        navigate(getRoute(userRouteNames.profile.root, userRouteNames.profile.password));
    };

    const onViewDevices = () => {
        navigate(getRoute(userRouteNames.profile.root, userRouteNames.profile.devices));
    };
    const actions = [
        <Button variant='contained' onClick={onChangePassword} startIcon={<Shield/>}>
            <FormattedMessage id={'user.buttons.changePassword'}/>
        </Button>,
    ];

    return (
        <Page
            maxWidth={'md'}
            loading={isLoading || isDevicesLoading}
            title={formatMessage({id: 'user.pages.profile'})}
        >
            {userNotFound && (<NoItemsExists title={<FormattedMessage id='admin.userPage.labels.userNotFound'/>}
                                             description={<FormattedMessage
                                                 id='admin.userPage.labels.userDetailsNotFound'/>}/>)}
            {!userNotFound && !!user && (
                <UserCard
                    actions={actions}
                    user={user}
                    devices={devices || []}
                    onDevicesView={onViewDevices}
                    canEdit
                    onEdit={onEdit}
                    canDelete
                    onDelete={onDelete}
                    deleting={isDeleting}
                />
            )}
        </Page>
    );
};
