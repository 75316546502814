import { Outlet } from 'react-router-dom';
import { UserRole } from '../../enums';

import { NotFoundPage } from '../../portals/shared';
import { useCurrentUser } from 'hooks';

type RoleGuardProps = {
  role: UserRole | UserRole[];
};
export const RoleGuard = ({ role }: RoleGuardProps) => {
  const { user } = useCurrentUser();

  if (user?.role.name === role || role.includes(user!.role.name)) {
    return <Outlet />;
  }

  return <NotFoundPage />;
};
