import {Delete,} from '@mui/icons-material';
import {Box, Card, CardActions, CardContent,} from '@mui/material';
import {Button} from 'components';
import {DeviceInfo, UserInfo} from 'models';
import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {UserCardDetails} from "./UserCardDetails";
import {UserCardHeader} from "./UserCardHeader";


export type UserCardProps = {
    user: UserInfo;
    devices?: DeviceInfo[];
    canDelete?: boolean;
    canEdit?: boolean;
    deleting?: boolean;
    onDelete?: (user: UserInfo) => void;
    onEdit?: (user: UserInfo) => void;
    showDetails?: boolean;
    width?: number;
    actions?: ReactNode[];
    onDevicesView?: (user: UserInfo) => void;
};

export const UserCard = ({
                             user,
                             canEdit = false,
                             canDelete = false,
                             devices = [],
                             deleting = false,
                             onDelete,
                             onEdit,
                             width,
                             showDetails = true,
                             actions,
                             onDevicesView,
                         }: UserCardProps) => {
    const handleDeleteUser = async () => {
        onDelete?.(user);
    };

    return (
        <Card elevation={2} sx={{width: width}}>
            <UserCardHeader user={user} canEdit={canEdit} onEdit={onEdit}/>
            <CardContent>
                {showDetails && (<UserCardDetails user={user} devices={devices} onDevicesView={onDevicesView}/>)}
            </CardContent>
            <CardActions sx={{m: 1, justifyContent: 'right'}}>
                {actions?.map((x, index) => (
                    <Box px={1} key={`card-actions-${index}`}>
                        {x}
                    </Box>
                ))}
                {canDelete && (
                    <Button
                        loading={deleting}
                        variant='text'
                        onClick={handleDeleteUser}
                        startIcon={<Delete/>}
                        color='error'>
                        <FormattedMessage id='global.user.buttons.delete'/>
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};


