import { Refresh } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

type RefreshButtonProps = {
  onClick: () => void;
};
export const RefreshButton = ({ onClick }: RefreshButtonProps) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    setIsRefreshing(true);
    onClick();
  };

  useEffect(() => {
    const refreshTimer = isRefreshing
      ? setTimeout(() => {
          setIsRefreshing(false);
        }, 500)
      : null;

    return () => {
      if (refreshTimer) clearTimeout(refreshTimer);
    };
  }, [isRefreshing]);

  return (
    <Tooltip title={<FormattedMessage id='global.tooltips.refresh' />}>
      <IconButton onClick={handleRefresh}>
        <Refresh
          sx={
            isRefreshing
              ? {
                  animation: `spin .5s infinite`,
                  animationTimingFunction: 'cubic-bezier(0.42, 0.2, 0.58, 1)',
                }
              : undefined
          }
        />
        <style>{`
              @keyframes spin {
                   0% { transform: rotate(0deg); }
                   100% { transform: rotate(360deg); }
              }
          `}</style>
      </IconButton>
    </Tooltip>
  );
};
