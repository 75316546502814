import { useSearchParams } from 'react-router-dom';
import { RouteParams } from '../../../constants';
import { VerifyEmailForm } from '../components';

export const VerifyEmailPage = () => {
  const [queryParams] = useSearchParams();

  const code = queryParams.get(RouteParams.verifyEmailCode) || '';

  return <VerifyEmailForm code={code} />;
};
