import { Route } from 'react-router-dom';
import { RouteParams } from '../../constants';
import { RequiredQueryParamGuard } from '../../routes/guards';
import { IdentityLayout } from './layout';
import {
  CompleteInvitePage,
  CompleteResetPasswordPage,
  LoginPage,
  ResetPasswordPage,
} from './pages';
import { VerifyEmailPage } from './pages/VerifyEmailPage';

export const identityRouteNames = {
  login: 'login',
  signUp: 'sign-up',
  resetPassword: 'reset-password',
  completeResetPassword: 'complete-reset-password',
  verifyEmail: 'verify-email',
  completeInvite: 'complete-invite',
};

export const identityRoutes = (
  <Route element={<IdentityLayout />}>
    <Route path={identityRouteNames.login} element={<LoginPage />} />
    <Route path={identityRouteNames.resetPassword} element={<ResetPasswordPage />} />
    <Route
      path={identityRouteNames.completeResetPassword}
      element={<RequiredQueryParamGuard paramName={RouteParams.resetPasswordToken} />}
    >
      <Route index element={<CompleteResetPasswordPage />} />
    </Route>
    <Route
      path={identityRouteNames.completeInvite}
      element={<RequiredQueryParamGuard paramName={RouteParams.invitationCode} />}
    >
      <Route index element={<CompleteInvitePage />} />
    </Route>
    <Route
      path={identityRouteNames.verifyEmail}
      element={<RequiredQueryParamGuard paramName={RouteParams.verifyEmailCode} />}
    >
      <Route index element={<VerifyEmailPage />} />
    </Route>
  </Route>
);
