import {SearchField} from 'components';
import {Page} from 'portals/main/shared';
import {useIntl} from 'react-intl';
import {useCurrentUser, useUsersFilter} from '../../../../hooks';
import apiServices from '../../../../services';
import {AddNewUserGlobalButton, UsersDataTable, UsersDataTableToolbar} from './components';
import {UserRole} from 'enums';
import {useMemo} from 'react';

export const ListUsersPage = () => {
    const {filter, setFilter} = useUsersFilter();
    const {formatMessage, formatDate} = useIntl();
    const {user} = useCurrentUser();

    const {
        data: filteredResponse,
        isLoading,
        isFetching,
        refetch,
    } = apiServices.admin.users.useFilterUsersQuery(filter);

    const handleSearch = (value: string) => {
        setFilter({...filter, page: 1, searchingEmail: value, searchingName: value});
    };

    const handleStatusChange = (status: 'active' | 'inactive' | undefined) => {
        setFilter({...filter, status: status, page: 1});
    };

    const handleRoleChange = (role?: UserRole) => {
        setFilter({...filter, role: role, page: 1});
    };

    const handleCreateDateChange = (date?: Date | null) => {
        setFilter({...filter, createdDate: date ? formatDate(date) : undefined, page: 1});
    };

    const handleRefresh = () => {
        refetch();
    };

    const handleClearFilter = () => {
        setFilter({
            ...filter,
            status: undefined,
            role: undefined,
            createdDate: undefined,
        });
    };

    const showClearBtn = useMemo(() => {
        return !!filter.createdDate || !!filter.role || !!filter.status;
    }, [filter]);

    return (
        <Page
            title={formatMessage({id: 'admin.pages.users'})}
            actions={[
                <SearchField onChange={handleSearch} value={filter.searchingEmail || ''}/>,
                <AddNewUserGlobalButton/>,
            ]}
        >
            <UsersDataTableToolbar
                onRefresh={handleRefresh}
                filter={{
                    status: filter.status,
                    role: filter.role,
                    createdDate: filter.createdDate ? new Date(filter.createdDate) : undefined,
                }}
                onStatusChange={handleStatusChange}
                onCreateDateChange={handleCreateDateChange}
                onRoleChange={handleRoleChange}
                canFilterRole={[UserRole.Admin, UserRole.Developer].some((x) => x === user?.role.name)}
                onClear={handleClearFilter}
                showClear={showClearBtn}
            />
            <UsersDataTable
                users={filteredResponse?.items || []}
                loading={isLoading || isFetching}
                totalCount={filteredResponse?.totalCount || 0}
                filteredCount={filteredResponse?.filteredCount || 0}
            />
        </Page>
    );
};
