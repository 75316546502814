import {Autocomplete, Box, MenuItem, TextField} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {UseFormSetError, useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';

import {zodResolver} from '@hookform/resolvers/zod';
import Typography from '@mui/material/Typography';
import {Country} from 'country-list';
import {useCurrentUser} from 'hooks';
import {ChangeUserInfoSchema} from 'models/schemas';
import {Form, PhoneInputField, getFormErrorMessage} from '../../../../components';
import {UserDegree, UserRole} from '../../../../enums';
import {ChangeUserInfoData, UserInfo} from '../../../../models';
import {countries as countryList} from 'utils';

const degreeValues = Object.values(UserDegree);
const countries = countryList.getData();

type CreateUserFormProps = {
    user: UserInfo | null;
    formName?: string;
    onSubmit: (value: ChangeUserInfoData, setFormError: UseFormSetError<ChangeUserInfoData>) => void;
};

export const UserForm = ({user, formName = 'creatingUser', onSubmit}: CreateUserFormProps) => {
    const {user: currentUser} = useCurrentUser();
    const {formatMessage} = useIntl();

    const currentUserRole = currentUser?.role.name || UserRole.Default;
    const roles = currentUserRole === UserRole.Sales ? [UserRole.Default] : Object.values(UserRole);

    const [selectedCountry, setSelectedCountry] = useState<Country | null>(
        countries.find((x) => x.code === user?.countryCode) || null,
    );

    const {
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm<ChangeUserInfoData>({
        mode: 'onBlur',
        resolver: zodResolver(ChangeUserInfoSchema),
        defaultValues:
            {
                ...user,
                role: user?.role.name
            } || {},
    });

    const onSubmitIntr = (data: ChangeUserInfoData) => {
        onSubmit?.(
            {...data, role: data.role || UserRole.Default, countryCode: selectedCountry?.code || null},
            setError,
        );
    };

    const canChangeRole = useMemo(() => {
        return !!user?.id ? false : currentUserRole !== UserRole.Default;
    }, [currentUser, user]);

    return (
        <Form formName={formName} onSubmit={handleSubmit(onSubmitIntr)}>
            <Typography variant={'h6'}>
                <FormattedMessage id={'admin.userPage.labels.generalInfo'}/>
            </Typography>
            {canChangeRole && (
                <TextField
                    required
                    select
                    size='medium'
                    defaultValue={UserRole.Default}
                    error={!!errors.role}
                    helperText={getFormErrorMessage(errors.role?.message)}
                    label={formatMessage({id: 'admin.userPage.labels.role'})}
                    {...register('role')}
                >
                    {roles.map((x, index) => (
                        <MenuItem key={`role-select-item-${index}`} value={x}>
                            {x}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <TextField
                type='text'
                required
                error={!!errors.firstName}
                helperText={getFormErrorMessage(errors.firstName?.message)}
                label={formatMessage({id: 'admin.userPage.labels.firstName'})}
                {...register('firstName', {
                    onChange: (e) => {
                        e.target.value = e.target.value.trimStart();
                    }
                })}
            />
            <TextField
                type='text'
                required
                error={!!errors.lastName}
                helperText={getFormErrorMessage(errors.lastName?.message)}
                label={formatMessage({id: 'admin.userPage.labels.lastName'})}
                {...register('lastName', {
                    onChange: (e) => {
                        e.target.value = e.target.value.trimStart();
                    }
                })}
            />
            <TextField
                type='email'
                required
                error={!!errors.email}
                helperText={getFormErrorMessage(errors.email?.message)}
                autoComplete='username'
                label={formatMessage({id: 'admin.userPage.labels.email'})}
                {...register('email')}
            />
            <TextField
                type='text'
                required
                error={!!errors.companyName}
                helperText={getFormErrorMessage(errors.companyName?.message)}
                label={formatMessage({id: 'admin.userPage.labels.companyName'})}
                {...register('companyName', {
                    onChange: (e) => {
                        e.target.value = e.target.value.trimStart();
                    }
                })}
            />
            <TextField
                type='text'
                required
                error={!!errors.jobTitle}
                helperText={getFormErrorMessage(errors.jobTitle?.message)}
                label={formatMessage({id: 'admin.userPage.labels.jobTitle'})}
                {...register('jobTitle', {
                    onChange: (e) => {
                        e.target.value = e.target.value.trimStart();
                    }
                })}
            />

            <TextField
                required
                select
                defaultValue={user?.degree || UserDegree.Specialist}
                helperText={getFormErrorMessage(errors.degree?.message)}
                error={!!errors.degree}
                label={formatMessage({id: 'admin.userPage.labels.degree'})}
                {...register('degree')}
            >
                {degreeValues.map((x, index) => (
                    <MenuItem key={`degree-select-item-${index}`} value={x}>
                        {x}
                    </MenuItem>
                ))}
            </TextField>

            <Typography variant={'h6'}>
                <FormattedMessage id={'admin.userPage.labels.personalInfo'}/>
            </Typography>
            <Autocomplete
                filterSelectedOptions
                autoHighlight
                defaultValue={countries.find((x) => x.code === user?.countryCode)}
                getOptionLabel={(x: Country) => x.name}
                options={countries}
                renderOption={(props, option) => (
                    <Box component='li' sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading='lazy'
                            width='20'
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=''
                        />
                        {option.name} ({option.code})
                    </Box>
                )}
                onChange={(e: any, newValue: Country | null) => {
                    setSelectedCountry(newValue);
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            type='text'
                            {...params}
                            error={!!errors.countryCode}
                            helperText={getFormErrorMessage(errors.countryCode?.message)}
                            label={formatMessage({id: 'admin.userPage.labels.countryCode'})}
                            {...register('countryCode')}
                            inputProps={{...params.inputProps, autoComplete: 'new-password'}}
                        />
                    );
                }}
            />
            <PhoneInputField
                error={!!errors.phoneNumber}
                helperText={getFormErrorMessage(errors.phoneNumber?.message)}
                value={user?.phoneNumber}
                label={formatMessage({id: 'admin.userPage.labels.phoneNumber'})}
                {...register('phoneNumber')}
            />
        </Form>
    );
};
