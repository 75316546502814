import {
  Checkbox,
  LinearProgress,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { NoItemsExists } from 'components/NoItemsExists';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AnyObject, Column, GetRowKey } from './types';

type DataTableBodyProps<RecordType = unknown> = {
  loading?: boolean;
  columns: Column<RecordType>[];
  data: readonly RecordType[];
  selectable?: boolean;
  selectedItems?: readonly string[];
  onSelectionChange?: (selected: readonly string[]) => void;
  rowKey?: string | keyof RecordType | GetRowKey<RecordType>;
};

export const DataTableBody = <RecordType extends AnyObject = AnyObject>({
  selectable = false,
  selectedItems = [],
  onSelectionChange,
  loading = false,
  columns,
  data,
  rowKey,
}: DataTableBodyProps<RecordType>) => {
  const handleClick = (event: React.ChangeEvent<unknown>, id: string) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1),
      );
    }

    onSelectionChange?.(newSelected);
  };
  const isSelected = (id: string) => selectedItems.indexOf(id) !== -1;

  const getRowKey = React.useMemo<GetRowKey<RecordType>>(() => {
    if (typeof rowKey === 'function') {
      return rowKey;
    }

    return (record: RecordType) => (record as any)?.[rowKey as string];
  }, [rowKey]);

  if (loading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={selectable ? columns.length + 1 : columns.length} align='center'>
            <LinearProgress sx={{ margin: 1 }} />
            <Typography color='grey.500' variant='body2'>
              <FormattedMessage id={'global.labels.loading'} />
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (!data.length) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={selectable ? columns.length + 1 : columns.length} align='center'>
            <NoItemsExists title={<FormattedMessage id={'global.labels.nothingFound'} />} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {data.map((row: RecordType, index) => {
        const rowId = getRowKey(row).toString();

        return (
          <TableRow hover role='checkbox' tabIndex={-1} key={getRowKey(row)}>
            {selectable && (
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={(event, checked) => handleClick(event, rowId)}
                  checked={isSelected(rowId)}
                  color='primary'
                />
              </TableCell>
            )}
            {columns.map((column) => {
              const value = row[column.dataIndex];
              return (
                <TableCell width={column.width} key={column.key} align={column.align}>
                  {column.render ? (
                    column.render(value, row, index)
                  ) : (
                    <Tooltip title={value}>
                      <Typography
                        variant='body2'
                        width={column.width}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {value}
                      </Typography>
                    </Tooltip>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
