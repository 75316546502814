import { Paper, TableContainer } from '@mui/material';
import Table from '@mui/material/Table/Table';
import React from 'react';
import { DataTableBody } from './DataTableBody';
import { DataTableHeader, Order } from './DataTableHeader';
import { DataTablePagination } from './DataTablePagination';
import { DataTableToolbar } from './DataTableToolbar';
import { AnyObject, Column, GetRowKey } from './types';

export type DataTableProps<RecordType = unknown> = {
  columns: Column<RecordType>[];
  data: readonly RecordType[];
  totalCount: number;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newSize: number) => void;
  loading?: boolean;
  selectable?: boolean;
  selectedItems?: readonly string[];
  onSelectionChange?: (items: readonly string[]) => void;
  rowKey?: string | keyof RecordType | GetRowKey<RecordType>;
  toolbarActions?: JSX.Element[];
  order?: Order;
  orderBy?: string;
  onSortChanged?: (order?: Order, orderBy?: string) => void;
};

export const DataTable = <RecordType extends AnyObject = AnyObject>({
  selectable = false,
  selectedItems = [],
  onSelectionChange,
  columns,
  data,
  totalCount,
  rowKey,
  loading = false,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  order,
  orderBy,
  onSortChanged,
  toolbarActions,
}: DataTableProps<RecordType>) => {
  const getRowKey = React.useMemo<GetRowKey<RecordType>>(() => {
    
    if (typeof rowKey === 'function') {
      return rowKey;
    }

    return (record: RecordType) => (record as any)?.[rowKey as string];
  }, [rowKey]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => getRowKey(n).toString());
      onSelectionChange?.(newSelected);
      return;
    }
    onSelectionChange?.([]);
  };

  return (
    <Paper elevation={1}>
      <TableContainer sx={{ maxHeight: '60vh' }}>
        <DataTableToolbar selectedCount={selectedItems.length} actions={toolbarActions} />
        <Table stickyHeader aria-label='sticky table'>
          <DataTableHeader
            columns={columns}
            selectable={selectable}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onSortChanged={onSortChanged}
            rowsCount={data.length}
            selectedCount={selectedItems.length}
          />
          <DataTableBody
            selectable={selectable}
            selectedItems={selectedItems}
            onSelectionChange={onSelectionChange}
            columns={columns}
            data={data}
            loading={loading}
            rowKey={rowKey}
          />
        </Table>
      </TableContainer>
      <DataTablePagination
        page={page}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        columns={columns}
        data={data}
        totalCount={totalCount}
      />
    </Paper>
  );
};
