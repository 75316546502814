import {alpha, Avatar, CardHeader, Chip, Grid, IconButton, Tooltip, Typography, useTheme} from "@mui/material";
import {countries, getAvatarProps} from "../../../../../utils";
import {BusinessCenter, Edit, Email, Phone, Public} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {styled} from "@mui/system";
import {UserInfo} from "../../../../../models";
import React from "react";

const maxChipWidth = 350;

type UserCardHeaderProps = {
    user: UserInfo;
    canEdit?: boolean;
    onEdit?: (user: UserInfo) => void;
}


export const UserCardHeader = ({user, canEdit, onEdit}: UserCardHeaderProps) => {
    const {palette} = useTheme();

    const userCountry = user.countryCode ? countries.getName(user.countryCode) : '';
    const userWorkPlace = !!user.companyName
        ? `${user.companyName}, ${user.jobTitle}`
        : user.jobTitle;

    const handleEditClick = () => {
        onEdit?.(user);
    };

    const items: HeaderItemProps[] = [
        {
            icon: <Public/>,
            label: userCountry,
        },
        {
            icon: <BusinessCenter/>,
            label: userWorkPlace
        },
        {
            icon: <Email/>,
            label: user.email,
            href: `mailto:${user.email}`,
            link: true

        },
        {
            icon: <Phone/>,
            label: user.phoneNumber,
            href: `tel:${user.phoneNumber}`,
            link: true

        }
    ];

    return (
        <CardHeader
            sx={{
                background: alpha(palette.primary.main, palette.action.activatedOpacity),
                p: 2,
            }}
            avatar={<Avatar {...getAvatarProps(`${user.firstName} ${user.lastName}`, 650 / 5)} />}
            title={
                <Typography gutterBottom variant='h5' sx={{fontWeight: 'bold'}}>
                    {user.firstName} {user.lastName}, {user.degree}
                </Typography>
            }
            subheader={
                <Grid container sx={{flexGrow: 1}} spacing={2}>
                    {items.map((x, index) => {
                            return !x.showEmpty && !x.label ? null : (
                                <Grid key={`user-header-item-${index}`} item maxWidth={maxChipWidth}>
                                    <HeaderItem  {...x}/>
                                </Grid>)
                        }
                    )}
                </Grid>
            }
            action={
                (canEdit) && (
                    <Tooltip title={<FormattedMessage id={'global.user.buttons.edit'}/>}>
                        <IconButton onClick={handleEditClick}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                )
            }
        />
    );

};

type HeaderItemProps = {
    link?: boolean;
    href?: string;
    label: React.ReactNode | string;
    icon?: React.ReactElement;
    showEmpty?: boolean;
}

const HeaderItem = ({link, href, label, showEmpty = false, icon}: HeaderItemProps) => {

    if (!showEmpty && !label) {
        return null
    }
    if (link)
        return (
            <Chip
                component={'a'}
                sx={{cursor: 'pointer'}}
                target='_top'
                rel='noopener noreferrer'
                href={href}
                icon={icon}
                label={label}
            />
        )

    return (
        <StyledChip icon={icon} label={label}/>
    )
}

const StyledChip = styled(Chip)(() => ({
    root: {
        maxWidth: '300px',
    }
}));