import { Button, ButtonProps, Form } from 'components';
import { FormEventHandler, PropsWithChildren, ReactNode } from 'react';
import { Title } from '../layout';

type IdentityFormProps = {
  title: ReactNode | string;
  loading?: boolean;
  submitText: ReactNode | string;
  submitButtonProps?: ButtonProps;
  formName?: string;
  onSubmit?: FormEventHandler<any> | undefined;
};

export const IdentityForm = ({
  title,
  loading,
  formName = 'identity-form',
  submitText,
  submitButtonProps,
  onSubmit,
  children,
}: PropsWithChildren<IdentityFormProps>) => {
  return (
    <>
      <Title mb={8} variant='h4'>
        {title}
      </Title>
      <Form formName={formName} onSubmit={onSubmit} mb={6}>
        {children}
      </Form>
      <Button
        type='submit'
        form={formName}
        fullWidth
        variant='contained'
        size='large'
        loading={loading}
        {...submitButtonProps}
      >
        {submitText}
      </Button>
    </>
  );
};
