import {
    Avatar,
    Button as BaseButton,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import {FormattedDate, FormattedMessage} from "react-intl";
import {CardMembershipOutlined, Devices, Event, NavigateNext, VerifiedUserOutlined} from "@mui/icons-material";
import {DeviceInfo, UserInfo} from "../../../../../models";
import React from "react";
import {UserRole} from "../../../../../enums";

type UserCardDetailsProps = {
    user: UserInfo;
    devices?: DeviceInfo[];
    onDevicesView?: (user: UserInfo) => void;
};

export const UserCardDetails = ({
                                    user,
                                    devices,
                                    onDevicesView,
                                }: UserCardDetailsProps) => {

    const getMembershipType = () => {
        switch (user.role.name) {
            case UserRole.Default:
                return <FormattedMessage id='global.user.membershipTypes.demo'/>
            case UserRole.Admin:
            case UserRole.Developer:
                return <FormattedMessage id='global.user.membershipTypes.supervisor'/>
            default:
                return <FormattedMessage id='global.user.membershipTypes.analytics'/>
        }
    }

    const items: DetailedItemProps[] = [
        {
            icon: <Event/>,
            primaryText: <FormattedMessage id='global.user.labels.createdDate'/>,
            secondaryText: <FormattedDate value={user.createdDate} dateStyle='medium' timeStyle='short'/>
        },
        {
            icon: <Event/>,
            primaryText: <FormattedMessage id='global.user.labels.updatedDate'/>,
            secondaryText: <FormattedDate value={user.lastUpdateDate || undefined} dateStyle='medium'
                                          timeStyle='short'/>
        },
        {
            icon: <VerifiedUserOutlined color={user.isActive ? 'success' : 'error'}/>,
            primaryText: <FormattedMessage id='global.user.labels.status'/>,
            secondaryText: <Typography variant='subtitle2' color={user.isActive ? 'success.main' : 'error.dark'}>
                <FormattedMessage id={user.isActive ? 'global.user.labels.active' : 'global.user.labels.inactive'}/>
            </Typography>
        },
        {
            icon: <CardMembershipOutlined/>,
            primaryText: <FormattedMessage id='global.user.labels.membership'/>,
            secondaryText: getMembershipType()
        },
        {
            icon: <Devices/>,
            primaryText: <FormattedMessage id='global.user.labels.devices'/>,
            secondaryText: <Typography>{devices?.length || 0}</Typography>,
            button: true,
            endIcon: <NavigateNext/>,
            onClick: () => onDevicesView?.(user),
            tooltip: <FormattedMessage id='global.user.tooltips.viewDevices'/>
        }
    ];

    return (
        <>
            <Typography variant='subtitle2'>
                <FormattedMessage id='global.user.labels.details'/>
            </Typography>
            <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
                {items.map((x, index) => (
                    <Grid item xs={2} sm={4} md={4} key={`user-detail-item-${index}`}>
                        <DetailedItem {...x}/>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

type DetailedItemProps = {
    icon: React.ReactNode;
    button?: boolean;
    onClick?: () => void;
    endIcon?: React.ReactNode;
    primaryText: React.ReactNode | string;
    secondaryText?: React.ReactNode | string;
    tooltip?: React.ReactNode;
}
const DetailedItem = ({icon, button, onClick, endIcon, primaryText, secondaryText, tooltip}: DetailedItemProps) => {

    if (button)
        return (
            <Tooltip arrow title={tooltip || primaryText}>
                <ListItem onClick={onClick} component={BaseButton} endIcon={endIcon}>
                    <ListItemAvatar>
                        <Avatar>
                            {icon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={primaryText}
                        secondary={secondaryText}/>
                </ListItem>
            </Tooltip>
        )

    return (
        <Tooltip arrow title={tooltip || primaryText}>
            <ListItem onClick={onClick}>
                <ListItemAvatar>
                    <Avatar>
                        {icon}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={primaryText}
                    secondary={secondaryText}
                />
            </ListItem>
        </Tooltip>
    )
}