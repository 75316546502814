import LogoIcon from '../../assets/logoIcon.svg';
import Logo from '../../assets/logo.svg';
import {Card, CardContent, Grid, Paper, Stack, styled, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Outlet} from "react-router-dom";

export const IdentityLayout = () => {
    const {palette, breakpoints, spacing} = useTheme();
    const desktop = useMediaQuery(breakpoints.up('md'));
    const small = useMediaQuery(breakpoints.up('sm'));

    if (desktop)
        return (
            <BodyWrapper>
                <StyledLogoIcon src={LogoIcon}/>
                <StyledLogo src={Logo}/>

                <LoginWindow elevation={0}>
                    <Outlet/>
                </LoginWindow>
            </BodyWrapper>
        )


    return (
        <Stack alignItems='center' justifyItems='center' p={2} sx={{
            minHeight: '100vh',
            backgroundColor: palette.primary.main,
        }}>
            <img alt='Logo' width='35%' style={{paddingBottom: spacing(4)}} src={Logo}/>
            <Card sx={{width: small ? '70%' : '100%', margin: 'auto'}}>
                <CardContent>
                    <Outlet/>
                </CardContent>
            </Card>
        </Stack>

    );
};

const BodyWrapper = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifySelf: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
}));

const LoginWindow = styled(Paper)(({theme}) => ({
    width: '40%',
    margin: 'auto',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    padding: '24px',
    maxWidth: '450px',
}));

export const Title = styled(Typography)(({theme}) => ({
    fontWeight: 700,
    fontFamily: "'Maven Pro', sans-serif",
    fontSize: '48px',
    color: theme.palette.primary.main,
    lineHeight: '56.4px',
    textAlign: 'center'
}));

export const StyledLogo = styled('img')({
    width: '25%',
    maxWidth: '499px',
    height: '30%',
    position: 'absolute',
    top: '30%',
    left: '8%',
});
export const StyledLogoIcon = styled('img')({
    margin: '5% 48px',
    width: '40%',
});
