import { BaseQueryApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { Mutex } from 'async-mutex';
import { identityApiUrl, unauthorizedStatus } from '../../env.constants';
import { logout, RootState, tokenReceived } from '../../store';
import { ApiErrorResponse } from '../responses';

const mutex = new Mutex();

type CreateDynamicQuery = (
  args: any,
  api: BaseQueryApi,
  extraOptions: object,
  { baseUrl }: { baseUrl: string },
) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, object>>;

const baseQuery: CreateDynamicQuery = (args, api, extraOptions, { baseUrl }) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Access-Control-Allow-Origin', window.location.hostname);
      headers.set('Accept', 'application/json');

      const state = getState() as RootState;

      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = state.auth.token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      headers.set('x-device-code', state.device.deviceId);
      headers.set('x-device-type', state.device.deviceType);

      return headers;
    },
    credentials: 'include',
  });

  return rawBaseQuery(args, api, extraOptions);
};

export const baseQueryWithReAuth: CreateDynamicQuery = async (
  args,
  api,
  extraOptions,
  { baseUrl },
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions, { baseUrl: baseUrl });

  if (result.error && result.error.status === unauthorizedStatus) {
    if (mutex.isLocked()) {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions, { baseUrl: baseUrl });
    } else {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: 'api/v1.0/identity/refresh-token',
            method: 'POST',
            body: {},
          },
          api,
          extraOptions,
          { baseUrl: identityApiUrl },
        );

        if (refreshResult.data) {
          api.dispatch(tokenReceived(refreshResult.data));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions, { baseUrl: baseUrl });
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    }
  }

  return result;
};

export const baseErrorResponseTransform = (error: FetchBaseQueryError) => {
  const apiError = error.data as ApiErrorResponse;
  return { status: error.status, ...apiError };
};
