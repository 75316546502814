import {AuthorizedGuard} from "../../routes/guards";
import {Route} from "react-router-dom";
import {MainLayout} from "./layout";
import {adminRoutes} from "./management";
import {userRoutes} from "./user";

export const mainPortalRoutes = (
    <Route element={<MainLayout/>}>
        {adminRoutes}
        {userRoutes}
    </Route>
)