import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserPaginationFilter } from 'models';
import { RootState } from 'store';
import {MIN_ROWS_PER_PAGE} from "../../utils/constants";


const initialState: UserPaginationFilter = {
  page: 1,
  pageSize: MIN_ROWS_PER_PAGE,
};

export const usersFilter = createSlice({
  name: 'usersFilter',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<UserPaginationFilter>) => {
      return { ...state, ...action.payload };
    },
    resetFilter: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { resetFilter, setFilter } = usersFilter.actions;
export const selectUsersFilter = (state: RootState) => state.usersFilter;

export default usersFilter.reducer;
