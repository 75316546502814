import { Alert, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import apiServices from 'services';
import { IdentityForm } from './IdentityForm';

type VerifyEmailFormProps = {
  code: string;
};

export const VerifyEmailForm = ({ code }: VerifyEmailFormProps) => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [verifyEmail, { isLoading }] = apiServices.identity.useVerifyEmailMutation();

  useEffect(() => {
    if (!code) {
      return;
    }

    verifyEmail({ code: code })
      .unwrap()
      .catch((err) => {
        if (err.status === 404) {
          setError(formatMessage({ id: 'identity.verifyEmail.errors.wrongEmailCode' }));
        } else {
          setError(formatMessage({ id: 'identity.verifyEmail.errors.commonVerifyEmail' }));
        }
      });
  }, [code]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <IdentityForm
        title={<FormattedMessage id={'identity.verifyEmail.title'} />}
        submitButtonProps={{
          color: 'inherit',
          onClick: () => navigate('/'),
        }}
        submitText={<FormattedMessage id='identity.verifyEmail.buttons.goToMain' />}
      >
        <Alert color='error'>{error}</Alert>
      </IdentityForm>
    );
  }

  return (
    <IdentityForm
      title={<FormattedMessage id={'identity.verifyEmail.title'} />}
      submitButtonProps={{
        color: 'inherit',
        onClick: () => navigate('/'),
      }}
      submitText={<FormattedMessage id='identity.verifyEmail.buttons.goToMain' />}
    >
      <Typography>
        <FormattedMessage id={'identity.verifyEmail.messages.verifyEmailSuccess'} />
      </Typography>
    </IdentityForm>
  );
};
