import {api} from "./http.service";
import {AuditLog, AuditLogsFilter, FilteredResult} from "../models";
import {baseErrorResponseTransform} from "./queries";

const baseUrl = 'api/v1.0/administration/auditLogs';

const auditLogsService = api.injectEndpoints({
    endpoints: (builder) => ({
        filterAuditLogs: builder.query<FilteredResult<AuditLog>, AuditLogsFilter>({
            query: ({entity, ...filter}) => ({
                url: `${entity}/${baseUrl}/filter`,
                method: 'GET',
                params: filter
            }),
            transformErrorResponse: baseErrorResponseTransform,
            providesTags: [{type: 'AuditLogs', id: 'filter'}],
        })
    })
});

export const {
    useFilterAuditLogsQuery,
    endpoints
} = auditLogsService;