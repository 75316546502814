import { identityRoutes, Root } from 'portals';
import { ErrorPage, NotFoundPage } from 'portals/shared';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { mainPortalRoutes } from '../portals/main';
import { AuthorizedGuard } from './guards';
import { RoleHandler } from './RoleHandler';
import {ErrorBoundary} from "react-error-boundary";

export const browserAppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<ErrorPage/>}>
      {identityRoutes}
      <Route element={<AuthorizedGuard />}>
        <Route path='/' element={<RoleHandler />}>
          {mainPortalRoutes}
        </Route>
      </Route>
      <Route path='not-found' element={<NotFoundPage />} />
      <Route path='*' element={<Navigate to={'/not-found'} />} />
    </Route>,
  ),
);

export const AppRouter = () => {
  return <RouterProvider router={browserAppRouter} />;
};
