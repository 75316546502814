import {UserRole} from "enums";
import {Route} from "react-router-dom";
import {RoleGuard} from "routes/guards";
import {MyProfilePage} from "./profile/MyProfilePage";
import {EditMyProfilePage} from "./profile/EditMyProfilePage";
import {ChangePasswordPage} from "./profile/ChangePasswordPage";
import {MyDevicesPage} from "./profile/MyDevicesPage";

export const userRouteNames = {
    profile: {
        root: 'profile',
        view: 'me/view',
        edit: 'me/edit',
        password: 'me/password',
        devices: 'me/devices'
    }
};

export const userRoutes = (
    <Route element={<RoleGuard role={[UserRole.Admin, UserRole.Developer, UserRole.Sales, UserRole.Default]}/>}>
        <Route path={userRouteNames.profile.root}>
            <Route path={userRouteNames.profile.view} element={<MyProfilePage/>}/>
            <Route path={userRouteNames.profile.edit} element={<EditMyProfilePage/>}/>
            <Route path={userRouteNames.profile.password} element={<ChangePasswordPage/>}/>
            <Route path={userRouteNames.profile.devices} element={<MyDevicesPage/>}/>
        </Route>
    </Route>
)