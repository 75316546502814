import { UserDegree, UserRole } from 'enums';
import { z } from 'zod';

export const ChangeUserInfoSchema = z.object({
  firstName: z
    .string()
    .nonempty({ message: 'global.forms.errors.firstName.required' })
    .min(2, { message: 'global.forms.errors.firstName.minLength' })
    .max(255, { message: 'global.forms.errors.firstName.maxLength' }),
  lastName: z
    .string()
    .nonempty({ message: 'global.forms.errors.lastName.required' })
    .min(2, { message: 'global.forms.errors.lastName.minLength' })
    .max(255, { message: 'global.forms.errors.lastName.maxLength' }),
  middleName: z.string().optional().nullable(),
  email: z
    .string()
    .nonempty({ message: 'global.forms.errors.email.required' })
    .email({ message: 'global.forms.errors.email.invalidFormat' }),
  degree: z.nativeEnum(UserDegree, { required_error: 'global.forms.errors.degree.required' }),
  customDegree: z.string().optional().nullable(),
  companyName: z.string().nonempty({ message: 'global.forms.errors.company.required' }),
  jobTitle: z.string().nonempty({ message: 'global.forms.errors.jobTitle.required' }),
  countryCode: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  role: z.nativeEnum(UserRole, { required_error: 'global.forms.errors.role.required' }),
});

export const UserInfoSchema = ChangeUserInfoSchema.extend({
  id: z.string().uuid(),
  fullName: z.string().nonempty(),
  createdDate: z.string().nonempty().datetime(),
  lastUpdateDate: z.nullable(z.string().datetime()),
  isActive: z.boolean(),
  hasMessage: z.boolean(),
  role: z.object(
    {
      name: z.nativeEnum(UserRole),
      value: z.number().positive(),
    },
    { required_error: 'global.forms.errors.role.required' },
  ),
});
