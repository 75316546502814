import { zodResolver } from '@hookform/resolvers/zod';
import { Lock } from '@mui/icons-material';
import { Link, Stack, TextField } from '@mui/material';
import { SingInData } from 'models';
import { SingInSchema } from 'models/schemas';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { PasswordField, getFormErrorMessage } from '../../../components';
import apiServices from '../../../services';
import { ApiErrorResponse } from '../../../services/responses';
import { setBackendError } from '../../../utils';
import { IdentityForm } from './IdentityForm';

type LoginFormProps = {
  onSuccess?: () => void;
  onResetPasswordClick?: () => void;
};

export const LoginForm = ({ onResetPasswordClick, onSuccess }: LoginFormProps) => {
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SingInData>({
    mode: 'onBlur',
    resolver: zodResolver(SingInSchema),
  });

  const [signIn, { isLoading }] = apiServices.identity.useSignInMutation();

  const onSubmit: SubmitHandler<SingInData> = async (data) => {
    signIn(data)
      .unwrap()
      .then(() => {
        onSuccess?.();
      })
      .catch((e: ApiErrorResponse) => {
        setBackendError(setError, e);
      });
  };

  return (
    <IdentityForm
      title={<FormattedMessage id={'identity.loginPage.title'} />}
      submitText={<FormattedMessage id={'identity.loginPage.buttons.login'} />}
      loading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        type='email'
        error={!!errors.email}
        helperText={getFormErrorMessage(errors.email?.message)}
        autoComplete='username'
        label={formatMessage({ id: 'identity.loginPage.labels.email' })}
        {...register('email')}
      />
      <PasswordField
        error={!!errors.password}
        helperText={getFormErrorMessage(errors.password?.message)}
        autoComplete='current-password'
        label={formatMessage({ id: 'identity.loginPage.labels.password' })}
        {...register('password')}
      />
      <Link
        variant={'body1'}
        onClick={onResetPasswordClick}
        sx={{ cursor: 'pointer', fontWeight: '600' }}
        underline='hover'
        alignContent='center'
        textAlign='left'
      >
        <Stack spacing={1} direction='row' alignContent='center'>
          <Lock sx={{ fontSize: 24 }} />
          <FormattedMessage id={'identity.loginPage.links.forgotPassword'} />
        </Stack>
      </Link>
    </IdentityForm>
  );
};
