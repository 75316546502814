import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { identityRouteNames } from '../../portals';

export const AuthorizedGuard = () => {
  const { isAuth } = useAuth();
  const location = useLocation();

  if (isAuth) {
    return <Outlet />;
  }

  return (
    <Navigate
      to={`${identityRouteNames.login}?redirect=${location.pathname}`}
      state={{ from: location }}
    />
  );
};
