import {useNavigate, useParams} from 'react-router-dom';
import {Page, UserForm} from '../../shared';
import {FormattedMessage, useIntl} from 'react-intl';
import apiServices from '../../../../services';

import {Avatar, Box, Card, CardActions, CardContent, Stack,} from '@mui/material';
import React, {useMemo} from 'react';
import {ChangeUserInfoData} from '../../../../models';

import {useSnackbar} from 'notistack';
import {getAvatarProps, setBackendError} from 'utils';
import {Button, NoItemsExists} from 'components';
import {ApiErrorResponse} from 'services/responses';
import {UseFormSetError} from 'react-hook-form';
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export const EditUserPage = () => {
    const {userId} = useParams();
    const formName = 'editingUser';
    const {formatMessage} = useIntl();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {data: user, isLoading, error} = apiServices.admin.users.useGetUserByIdQuery({
        userId: userId || '',
    });
    const [updateUser, {isLoading: isUpdating}] = apiServices.admin.users.useUpdateUserMutation();

    const userNotFound = useMemo(() => {
        return (error as FetchBaseQueryError)?.status === 404;
    }, [error]);

    const handleForm = (user: ChangeUserInfoData, setError: UseFormSetError<ChangeUserInfoData>) => {
        updateUser({
            ...user,
            id: userId || '',
        })
            .unwrap()
            .catch((error: ApiErrorResponse) => {
                setBackendError(setError, error);
            })
            .then(() => {
                enqueueSnackbar(
                    formatMessage(
                        {id: 'admin.userPage.notifications.userUpdated'},
                        {
                            firstName: user.firstName,
                            lastName: user.lastName,
                        },
                    ),
                    {variant: 'success'},
                );

                navigate(-1);
            });
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Page
            goBackProps={{show: true, destination: 'back'}}
            maxWidth={'sm'}
            loading={isLoading}
            title={formatMessage({id: 'admin.pages.editUser'})}
        >
            {userNotFound && (<NoItemsExists title={<FormattedMessage id='admin.userPage.labels.userNotFound'/>}
                                             description={<FormattedMessage
                                                 id='admin.userPage.labels.userDetailsNotFound'/>}/>)}
            {!userNotFound && !!user && (
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <Box>
                                <Avatar {...getAvatarProps(`${user.firstName} ${user.lastName}`, 140)} />
                            </Box>
                            <UserForm user={user} onSubmit={handleForm} formName={formName}/>
                        </Stack>
                    </CardContent>
                    <CardActions sx={{m: 1}}>
                        <Button onClick={handleCancel} color='inherit' fullWidth variant='text'>
                            <FormattedMessage id={'global.buttons.cancel'}/>
                        </Button>
                        <Button
                            loading={isUpdating}
                            fullWidth
                            type='submit'
                            form={formName}
                            variant='contained'
                        >
                            <FormattedMessage id={'global.buttons.save'}/>
                        </Button>
                    </CardActions>
                </Card>
            )}
        </Page>
    );
};
