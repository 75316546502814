import { UserInfo } from '../models';
import { identityApi } from './http.service';
import { baseErrorResponseTransform } from './queries';
import { ChangePasswordRequest, UpdateMyUserRequest } from './requests';

const baseUrl = 'users/me';

const userMeService = identityApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyUser: builder.query<UserInfo, void>({
      query: () => ({
        url: baseUrl,
        method: 'GET',
      }),

      transformErrorResponse: baseErrorResponseTransform,
      providesTags: [{ type: 'Users', id: 'me' }],
    }),
    changeMyPersonalInfo: builder.mutation<void, UpdateMyUserRequest>({
      query: (user) => ({
        url: `${baseUrl}/profile`,
        method: 'PUT',
        body: user,
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: [{ type: 'Users', id: 'me' }],
    }),
    changeMyPassword: builder.mutation<void, ChangePasswordRequest>({
      query: (data) => ({
        url: `${baseUrl}/password`,
        method: 'PUT',
        body: data,
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: [{ type: 'Users', id: 'me' }],
    }),
    deleteMyUser: builder.mutation<void, void>({
      query: () => ({
        url: baseUrl,
        method: 'DELETE',
      }),
      transformErrorResponse: baseErrorResponseTransform,
    }),
  }),
});

export const {
  useGetMyUserQuery,
  useChangeMyPersonalInfoMutation,
  useChangeMyPasswordMutation,
  useDeleteMyUserMutation,
  endpoints,
  util,
} = userMeService;
