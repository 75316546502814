import {SubmitHandler, useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {PasswordField, getFormErrorMessage, PasswordRequirements} from '../../../components';
import apiServices from '../../../services';

import {zodResolver} from '@hookform/resolvers/zod';
import {CompleteResetPasswordData} from 'models';
import {CompleteResetPasswordSchema} from 'models/schemas';
import {ApiErrorResponse} from '../../../services/responses';
import {setBackendError} from '../../../utils';
import {IdentityForm,} from './IdentityForm';
import {useState} from 'react';
import {Alert} from '@mui/material';

type CompleteResetPasswordFormProps = {
    resetToken: string;
    onSuccess?: () => void;
};

export const CompleteResetPasswordForm = ({
                                              resetToken,
                                              onSuccess,
                                          }: CompleteResetPasswordFormProps) => {
    const {formatMessage} = useIntl();
    const [apiError, setApiError] = useState<string>();

    const {
        register,
        setError,
        trigger,
        handleSubmit,
        formState: {errors, dirtyFields},
    } = useForm<CompleteResetPasswordData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        resolver: zodResolver(CompleteResetPasswordSchema),
    });
    const [completeResetPassword, {isLoading}] =
        apiServices.identity.useCompleteResetPasswordMutation();

    const onSubmit: SubmitHandler<CompleteResetPasswordData> = (data) => {
        completeResetPassword({
            ...data,
            resetToken: resetToken,
        })
            .unwrap()
            .then(() => {
                onSuccess?.();
            })
            .catch((e: ApiErrorResponse) => {
                setBackendError(setError, e);
                if (e.detailedMessages) setApiError(e.detailedMessages[0]);
            });
    };

    return (
        <IdentityForm
            loading={isLoading}
            onSubmit={handleSubmit(onSubmit)}
            title={<FormattedMessage id={'identity.completeResetPasswordPage.title'}/>}
            submitText={
                <FormattedMessage id={'identity.completeResetPasswordPage.buttons.changePassword'}/>
            }
        >
            {!!apiError && <Alert severity='error'>{apiError}</Alert>}
            <PasswordField
                error={!!errors.newPassword}
                helperText={getFormErrorMessage(errors.newPassword?.message)}
                autoComplete='new-password'
                label={formatMessage({id: 'identity.completeResetPasswordPage.labels.newPassword'})}
                {...register('newPassword', {
                    onChange: async (e) => {
                        if (e.target.value && dirtyFields.confirmPassword) {
                            await trigger(['confirmPassword'])
                        }
                    },
                })}
            />

            <PasswordField
                error={!!errors.confirmPassword}
                helperText={getFormErrorMessage(errors.confirmPassword?.message)}
                autoComplete='new-password'
                label={formatMessage({
                    id: 'identity.completeResetPasswordPage.labels.confirmPassword',
                })}
                {...register('confirmPassword', {
                    onChange: async (e) => {
                        if (e.target.value && dirtyFields.newPassword) {
                            await trigger(['newPassword'])
                        }
                    },
                })}
            />
            <PasswordRequirements errors={errors.newPassword?.types} touched={dirtyFields.newPassword}/>

        </IdentityForm>
    );
};
