import {Route} from 'react-router-dom';
import {UserRole} from '../../../enums';
import {RoleGuard} from '../../../routes/guards';
import {EditUserPage, ListUsersPage, UserViewPage} from './users';
import {UserDevicesPage} from './users/UserDevicesPage';
import {AuditLogsPage} from "./analytics";

export const UserRouteKey = 'userId';

export const adminRouteNames = {
    management: {
        root: 'management',
        users: {
            root: 'users',
            view: (id: string = ':' + UserRouteKey) => `users/${id}/view`,
            devices: (id: string = ':' + UserRouteKey) => `users/${id}/devices`,
            edit: (id: string = ':' + UserRouteKey) => `users/${id}/edit`,
        },
    },
    analytics: {
        root: 'analytics',
        devices: 'devices',
        auditLogs: 'audit-logs',
    },
};

export const adminRoutes = (
    <Route element={<RoleGuard role={[UserRole.Admin, UserRole.Developer, UserRole.Sales]}/>}>
        <Route path={adminRouteNames.management.root}>
            <Route path={adminRouteNames.management.users.root} element={<ListUsersPage/>}/>
            <Route path={adminRouteNames.management.users.view()} element={<UserViewPage/>}/>
            <Route path={adminRouteNames.management.users.edit()} element={<EditUserPage/>}/>
            <Route path={adminRouteNames.management.users.devices()} element={<UserDevicesPage/>}/>
        </Route>
        <Route path={adminRouteNames.analytics.root}>
            {/*<Route path={adminRouteNames.analytics.devices} element={<DevicesPage/>}/>*/}
            <Route path={adminRouteNames.analytics.auditLogs} element={<AuditLogsPage/>}/>
        </Route>
    </Route>
);
