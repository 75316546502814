import {InputAdornment, Slide, TextField, TextFieldProps, Tooltip} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {FormattedMessage} from 'react-intl';
import Paper from '@mui/material/Paper';
import {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {Close} from '@mui/icons-material';

export const SearchField = ({
                                onChange,
                                value,
                                sx,
                                ...other
                            }: Omit<TextFieldProps, 'onChange'> & {
    onChange: (value: string) => void;
}) => {
    const [searchTerm, setSearchTerm] = useState<string>(String(value));

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (value !== searchTerm) onChange(searchTerm);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    return (
        <Paper elevation={!!value ? 2 : 0} sx={{'&:hover': {boxShadow: 2}}}>
            <TextField
                size='medium'
                sx={{minWidth: 450, ...sx}}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='start'>
                            <>
                                {!value && <SearchIcon/>}
                                {value && (
                                    <Tooltip title={<FormattedMessage id={'global.tooltips.clear'}/>}>
                                        <IconButton size='small' onClick={() => setSearchTerm('')}>
                                            <Close/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </>
                        </InputAdornment>
                    ),
                }}
                label={<FormattedMessage id={'global.labels.search'}/>}
                value={searchTerm}
                {...other}
            />
        </Paper>
    );
};
