const devIdentityApi: string = 'http://localhost:5108';
const prodIdentityApi: string =
  process.env.REACT_APP_IDENTITY_SERVER_URL ??
  (() => {
    throw new Error('"REACT_APP_IDENTITY_SERVER_URL" environment variable is not define');
  })();

const devApi: string = 'http://localhost:5105';
const prodApi: string =
  process.env.REACT_APP_WEBAGG_SERVER_URL ??
  (() => {
    throw new Error('"REACT_APP_WEBAGG_SERVER_URL" environment variable is not define');
  })();

const devDownloadAppUrl: string =
  'https://dev-app.bodyscope.health/downloads/development/latest/bodyscope.web.installer/Bodyscope.WebInstaller.Desktop.exe';
const propdDownloadAppUrl: string =
  process.env.REACT_APP_DOWNLOAD_APP_URL ??
  (() => {
    throw new Error('"REACT_APP_DOWNLOAD_APP_URL" environment variable is not define');
  })();

export const identityApiUrl: string =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? devIdentityApi
    : prodIdentityApi;

export const aggregatorApiUrl: string =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? devApi : prodApi;

export const downloadAppUrl:string = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? devDownloadAppUrl : propdDownloadAppUrl;

export const unauthorizedStatus = 401;
