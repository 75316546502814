import * as identityService from './identity.service';
import * as adminUsersService from './admin.users.service';
import * as adminDevicesService from './admin.devices.service';
import * as adminAuditLogsService from './admin.auditLogs.service';

import * as userMeService from './me.users.service';
import * as devicesMeService from './me.devices.service';

const apiServices = {
    identity: identityService,
    admin: {
        users: adminUsersService,
        devices: adminDevicesService,
        auditLogs: adminAuditLogsService
    },
    me: {
        users: userMeService,
        devices: devicesMeService,
    }
};

export default apiServices;
