import {Divider, Grid, MenuItem, TextField, Toolbar, useTheme,} from '@mui/material';
import {DatePicker, DateValidationError} from '@mui/x-date-pickers';
import {ClearFiltersButton, RefreshButton} from 'components';
import {UserRole} from 'enums';
import {FormattedMessage} from 'react-intl';
import React from "react";

type UsersDataTableToolbarProps = {
    onRefresh: () => void;
    filter?: {
        status: 'active' | 'inactive' | undefined;
        role?: UserRole;
        createdDate?: Date;
    };
    onStatusChange: (status: 'active' | 'inactive' | undefined) => void;
    onRoleChange?: (role?: UserRole) => void;
    onCreateDateChange: (date?: Date | null) => void;
    canFilterRole?: boolean;
    onClear?: () => void;
    showClear?: boolean;
};

export const UsersDataTableToolbar = ({
                                          onRefresh,
                                          onStatusChange,
                                          onRoleChange,
                                          canFilterRole,
                                          onCreateDateChange,
                                          filter,
                                          onClear,
                                          showClear,
                                      }: UsersDataTableToolbarProps) => {
    const {palette} = useTheme();
    const handleRefresh = () => {
        onRefresh();
    };

    const handleStatusChange = (value: string) => {
        if (value) {
            if (value === 'active') onStatusChange('active');
            else onStatusChange('inactive');
        } else {
            onStatusChange(undefined);
        }
    };

    const [error, setError] = React.useState<DateValidationError | null>(null);

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'invalidDate':
                return <FormattedMessage id='global.forms.errors.creationDate.invalidFormat'/>;
            case 'disableFuture':
                return <FormattedMessage id='global.forms.errors.creationDate.disableFuture'/>;

            default: {
                return '';
            }
        }
    }, [error]);

    return (
        <Toolbar disableGutters sx={{mb: 1, py: 2}}>
            <Grid container spacing={2} sx={{flexGrow: 1, marginRight: 1}}>
                <Grid item xs={4}>
                    <TextField
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        size='small'
                        fullWidth
                        value={filter?.status || ''}
                        onChange={(e) => handleStatusChange(e.target.value as string)}
                        sx={{background: palette.background.paper}}
                    >
                        <MenuItem value=''>
                            <FormattedMessage id='admin.userPage.labels.allStatuses'/>
                        </MenuItem>
                        <Divider/>
                        <MenuItem value={'active'}>
                            <FormattedMessage id='admin.userPage.labels.active'/>
                        </MenuItem>
                        <MenuItem value={'inactive'}>
                            <FormattedMessage id='admin.userPage.labels.inactive'/>
                        </MenuItem>
                    </TextField>
                </Grid>
                {canFilterRole && (
                    <Grid item xs={4}>
                        <TextField
                            select
                            size='small'
                            fullWidth
                            SelectProps={{
                                displayEmpty: true,
                            }}
                            value={filter?.role || ''}
                            onChange={(e) => onRoleChange?.(e.target.value as UserRole)}
                            sx={{background: palette.background.paper}}
                        >
                            <MenuItem value=''>
                                <FormattedMessage id='admin.userPage.labels.allRoles'/>
                            </MenuItem>
                            <Divider/>
                            {Object.keys(UserRole).map((x, index) => (
                                <MenuItem key={`filter-role-${index}`} value={x}>
                                    <FormattedMessage id={`global.roles.${x}`}/>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                )}
                <Grid item xs={4}>
                    <DatePicker
                        disableFuture
                        value={filter?.createdDate || null}
                        onError={(newError) => setError(newError)}
                        slotProps={{
                            textField: {size: 'small', helperText: errorMessage},
                            field: {clearable: true}
                        }}
                        onChange={(value?: Date | null) => {
                            onCreateDateChange(value);
                        }}
                        sx={{background: palette.background.paper, width: '100%'}}
                        label={<FormattedMessage id='admin.userPage.labels.createdDate'/>}
                    />
                </Grid>
            </Grid>
            {showClear && <ClearFiltersButton onClick={() => onClear?.()}/>}
            <RefreshButton onClick={handleRefresh}/>
        </Toolbar>
    );
};