import { DialogProps, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, ButtonProps } from 'components';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type ModalProps = Omit<DialogProps, 'title' | 'onClose'> & {
  title?: ReactNode | string;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  okText?: ReactNode | string;
  cancelText?: ReactNode | string;
  onOk?: () => void;
  onCancel: () => void;
};

export const Modal = ({
  title,
  children,
  onOk,
  onCancel,
  okText,
  cancelText,
  okButtonProps: { loading: okLoading, ...otherOkButtonProps } = {
    loading: false,
  },
  cancelButtonProps: { loading: cancelLoading, ...otherCancelButtonProps } = {
    loading: false,
  },
  ...other
}: ModalProps) => {
  return (
    <Dialog maxWidth='xs' PaperComponent={Paper} onClose={onCancel} fullWidth {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ paddingTop: '6px' }}>{children}</DialogContent>
      <DialogActions sx={{ marginX: 2, marginY: 2 }}>
        <Button
          color='inherit'
          loading={cancelLoading}
          fullWidth
          onClick={onCancel}
          variant='text'
          {...otherCancelButtonProps}
        >
          {!!cancelText ? cancelText : <FormattedMessage id={'global.buttons.cancel'} />}
        </Button>
        <Button
          loading={okLoading}
          fullWidth
          onClick={onOk}
          variant='contained'
          {...otherOkButtonProps}
        >
          {!!okText ? okText : <FormattedMessage id={'global.buttons.ok'} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
