import * as React from 'react';
import {CSSProperties, PropsWithChildren, ReactNode, useMemo} from 'react';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import {MainMenuItem} from "./mainMenuItem";
import {ListItem} from '@mui/material';
import {useLocation, useNavigate} from "react-router-dom";

const drawerWidth = 240;

export type MenuItem = {
    title: ReactNode | string,
    key: string,
    icon?: ReactNode,
    selectedIcon?: ReactNode;
    style?: CSSProperties,
    onClick?: () => void;
    children?: MenuItem[];
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


type MainMenuProps = {
    collapsed: boolean;
    items: MenuItem[];
}

export const MainMenu = ({collapsed, items, children}: PropsWithChildren<MainMenuProps>) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnMenuClick = (item: MenuItem) => {
        if (item.children?.length) {
            return;
        }
        navigate(item.key, {state: location});
    };

    const currentKey = useMemo(() => {
        let key: string;
        if (location.pathname === '/')
            key = items.find(x => location.pathname === `/${x!.key}`)?.key as string;
        else
            key = items.find(x => x?.key && location.pathname.startsWith(`/${x!.key}`))?.key as string;

        return key;
    }, [items, location]);

    return (
        <Drawer
            open={collapsed}
            defaultValue={currentKey}
            variant="permanent">
            <Toolbar/>
            <List>
                {!!children && (<ListItem>
                    {children}
                </ListItem>)}
                {items.map((item, index) => (
                    <MainMenuItem key={`menu-item-${index}`} selected={item.key === currentKey}
                                  item={item}
                                  onClick={handleOnMenuClick}
                                  collapsed={collapsed}/>
                ))}
            </List>
        </Drawer>
    );
}



