import { PaletteOptions } from '@mui/material';

const darkPrimaryPalette = {
  light: '#6073f2',
  main: '#4058ed',
  dark: '#3a4ee1',
  contrastText: '#fff',
};

const darkSecondaryPalette = {
  light: '#e6e7fd',
  main: '#909ef6',
  dark: '#3c5bdc',
  contrastText: '#000',
};

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: darkPrimaryPalette,
  secondary: darkSecondaryPalette,
  background: {
    default: '#323232',
  },
};
