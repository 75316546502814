import {Grid, MenuItem, Paper, TextField, ToggleButton, ToggleButtonGroup, Toolbar, useTheme} from "@mui/material";
import {ClearFiltersButton, RefreshButton} from "../../../../../../components";
import React from "react";
import {AuditType} from "../../../../../../enums";
import {FormattedMessage} from "react-intl";

type AuditLogsDataTableToolbarProps = {
    onRefresh: () => void;
    filter?: {
        auditType?: AuditType | '-1';
        entity?: string;
    };
    onAuditTypeChange?: (auditType?: AuditType) => void;
    onEntityChange?: (entity?: string) => void;
    onClear?: () => void;
    showClear?: boolean;
};

const auditTypes = Object.values(AuditType);

export const AuditLogsDataTableToolbar = ({
                                              onRefresh,
                                              filter,
                                              onAuditTypeChange,
                                              onEntityChange,
                                              onClear,
                                              showClear
                                          }: AuditLogsDataTableToolbarProps) => {

    const {palette} = useTheme();
    const handleAuditTypeChange = (value: AuditType | '-1') => {
        if (value === '-1') {
            onAuditTypeChange?.(undefined);
        } else {
            onAuditTypeChange?.(value);
        }
    }
    return (
        <Toolbar disableGutters sx={{py: 2}}>
            <Grid container spacing={2} sx={{flexGrow: 1, marginRight: 1}}>
                <Grid item xs={4}>
                    <TextField
                        select
                        size='small'
                        fullWidth
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        value={filter?.entity || 'm'}
                        onChange={(e) => onEntityChange?.(e.target.value)}
                        sx={{background: palette.background.paper}}
                    >
                        <MenuItem value={'m'}>
                            <FormattedMessage id={`admin.auditLogs.entities.meetings`}/>
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={0}>
                        <ToggleButtonGroup color="primary"
                                           size='small'
                                           fullWidth
                                           value={filter?.auditType}
                                           exclusive
                                           onChange={(event, value) => handleAuditTypeChange(value)}>
                            <ToggleButton tabIndex={0} value={'-1'}>
                                <FormattedMessage id='admin.auditLogs.buttons.allAuditTypes'/>
                            </ToggleButton>
                            {auditTypes.map((x, index) => (
                                <ToggleButton tabIndex={index + 1} key={`audit-type-${index}`}
                                              value={x}>{x}</ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Paper>
                </Grid>
            </Grid>
            {showClear && (<ClearFiltersButton onClick={() => onClear?.()}/>)}
            <RefreshButton onClick={onRefresh}/>
        </Toolbar>
    )
}