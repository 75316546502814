import { ApiErrorResponse } from '../services/responses';
import { UseFormSetError } from 'react-hook-form';
import camelCase from 'camelcase';

export const setBackendError = (setError: UseFormSetError<any>, error: ApiErrorResponse) => {
  const errors = error.additionalErrors || error.errors;
  console.log(error);
  if (errors) {
    const errorKeys = Object.entries(errors);
    errorKeys.forEach((x) => {
      setError(camelCase(x[0]), { message: x[1] });
    });
  }
};
