import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from 'notistack';
import React from 'react';
import { SnackbarUtilsConfigurator } from 'utils';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: 'success',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: 'error',
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: 'info',
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: 'warning',
  },
}));

type AppNotificationsProps = {};

export const AppNotifications = ({ children }: React.PropsWithChildren<AppNotificationsProps>) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      action={(snackbarId) => (
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={() => closeSnackbar(snackbarId)}
        >
          <Close fontSize='small' />
        </IconButton>
      )}
    >
        <SnackbarUtilsConfigurator/>
      {children}
    </SnackbarProvider>
  );
};
