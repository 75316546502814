import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteParams } from '../../../constants';
import { useAuth } from '../../../hooks';
import apiServices from '../../../services';
import { useAppDispatch } from '../../../store';
import { LoginForm } from '../components/LoginForm';
import { identityRouteNames } from '../index';

export const LoginPage = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const redirectUrl = queryParams.get(RouteParams.redirectUrl) || '/';
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    dispatch(apiServices.me.users.endpoints.getMyUser.initiate()).then(() => {
      navigate(redirectUrl);
    });
  };
  const onResetPasswordClick = () => {
    navigate('/' + identityRouteNames.resetPassword);
  };

  useEffect(() => {
    if (isAuth) {
      console.log('User is authorized');
      navigate('/');
    }
  }, [isAuth, navigate]);

  return <LoginForm onResetPasswordClick={onResetPasswordClick} onSuccess={onSubmit} />;
};
