import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as NotFoundSvg } from '../assets/notFound.svg';

type NoItemsExistsProps = {
  title: ReactNode | string;
  description?: ReactNode | string;
  action?: ReactNode;
};
export const NoItemsExists = ({ title, description, action }: NoItemsExistsProps) => {
  const { palette } = useTheme();
  return (
    <Stack spacing={2} alignItems='center' m={2}>
      <Typography variant='h4'>{title}</Typography>
      <Typography variant='body2'>{description}</Typography>
      <Box
        sx={{
          background: alpha(palette.primary.main, palette.action.activatedOpacity),
          p: 2,
          borderRadius: 1,
        }}
      >
        <NotFoundSvg width={'100%'} />
      </Box>
      {action}
    </Stack>
  );
};
