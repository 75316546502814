import { CheckCircleOutlined, Replay } from '@mui/icons-material';
import { Alert, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { IdentityForm } from './IdentityForm';

type EmailSentResultProps = {
  onResendClick?: () => void;
  loading?: boolean;
};

export const EmailSentResult = ({ onResendClick, loading }: EmailSentResultProps) => {
  return (
    <IdentityForm
      loading={loading}
      title={<FormattedMessage id={'identity.resetPasswordPage.title'} />}
      submitText={<FormattedMessage id={'identity.resetPasswordPage.buttons.sendAgain'} />}
      submitButtonProps={{
        variant: 'text',
        type: 'button',
        startIcon: <Replay />,
        onClick: onResendClick,
      }}
    >
      <Stack alignItems='center'>
        <Alert color='success'>
          <FormattedMessage id={'identity.resetPasswordPage.text.emailSent'} />
        </Alert>
      </Stack>
    </IdentityForm>
  );
};
