export const getAvatarProps = (userName: string, size: number) => {
    return {
        sx: {
            width: size,
            height: size,
            backgroundColor: 'primary.light',
            fontSize: size > 100 ? '3rem' : '1rem'
        },
        children: `${userName.split(' ')[0][0]}${userName.split(' ')[1][0]}`,
    };
}