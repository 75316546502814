import { Navigate, Outlet, useLocation } from 'react-router-dom';
import apiServices from 'services';
import { Box, CircularProgress } from '@mui/material';
import { identityRouteNames } from '../portals';
import { adminRouteNames } from '../portals/main/management';
import { UserRole } from 'enums';
import { userRouteNames } from 'portals/main/user';

export const RoleHandler = () => {
  const { data: user, isLoading } = apiServices.me.users.useGetMyUserQuery();
  const location = useLocation();

  if (location.pathname !== '/') {
    return <Outlet />;
  }

  if (isLoading)
    return (
      <Box style={{ minHeight: '100vh' }}>
        <CircularProgress style={{ margin: 'auto' }} size={'large'} />
      </Box>
    );

  if (!user?.role) {
    console.log('Redirect to login page');
    return <Navigate to={identityRouteNames.login} state={{ from: location }} />;
  }

  if (user.role.name === UserRole.Default) {
    console.log('Redirect to profile');
    return (
      <Navigate
        to={`${userRouteNames.profile.root}/${userRouteNames.profile.view}`}
        state={{ from: location }}
      />
    );
  }

  console.log('Redirect to management');

  return (
    <Navigate
      to={`${adminRouteNames.management.root}/${adminRouteNames.management.users.root}`}
      state={{ from: location }}
    />
  );
};
