import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as storageService from '../../services/storage.service';
import { v4 as uuidv4 } from 'uuid';
import { DEVICE_CODE_KEY } from '../../utils/constants';

interface DeviceState {
  deviceId: string;
  deviceType: string;
}

const initialState: DeviceState = {
  deviceId: storageService.get<string>(DEVICE_CODE_KEY),
  deviceType: 'Web',
};

const deviceSlice = createSlice({
  name: 'device',
  initialState: initialState,
  reducers: {
    initDeviceSession: (state, { payload }) => {
      storageService.set(DEVICE_CODE_KEY, payload);
      state.deviceId = payload;
    },
  },
});

export const { initDeviceSession } = deviceSlice.actions;

export default deviceSlice.reducer;

export const selectCurrentDeviceSession = (state: RootState) => state.device;
