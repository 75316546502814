import {
  Pagination,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table/Table";
import { FormattedMessage } from "react-intl";
import { DropdownMenu } from "../DropdownMenu";
import { DataTableProps } from "./DataTable";
import { AnyObject } from "./types";

export const DataTablePagination = <RecordType extends AnyObject = AnyObject>({
  columns,
  totalCount,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
}: DataTableProps<RecordType>) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ border: 0 }}>
              <Grid container alignItems='center' justifyContent='space-between' direction='row'>
                <Grid item>
                  <Typography color='grey.500'>
                    <FormattedMessage
                      id={"global.labels.itemsCount"}
                      values={{ count: totalCount }}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography>
                      <FormattedMessage id={"global.labels.rowsPerPage"} />
                    </Typography>
                    <DropdownMenu
                      title={pageSize}
                      menuItems={[10, 15, 20, 50].map((x) => ({
                        title: x.toString(),
                        action: (_) => onPageSizeChange(x),
                      }))}
                    />
                    <Pagination
                      count={Math.ceil(totalCount / pageSize)}
                      siblingCount={1}
                      boundaryCount={1}
                      page={page}
                      onChange={(_, newPage) => onPageChange(newPage)}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
