import {configureStore} from '@reduxjs/toolkit';
import {api, identityApi} from '../services/http.service';
import authReducer from './features/auth.slice';
import deviceReducer from './features/device.slice';
import usersFilterReducer from './features/users.slice';
import auditLogsReducer from './features/auditLogs.slice';

import appReducer from './features/app.slice';

import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {ErrorNotificationMiddleware} from './middlewares';

const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [identityApi.reducerPath]: identityApi.reducer,

        app: appReducer,
        auth: authReducer,
        device: deviceReducer,
        usersFilter: usersFilterReducer,
        auditLogs: auditLogsReducer
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            api.middleware,
            identityApi.middleware,
            ErrorNotificationMiddleware,
        ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type Store = ReturnType<typeof store.getState>;

export default store;
