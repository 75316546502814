import { UserPaginationFilter } from 'models';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  selectUsersFilter,
  resetFilter as resetUsersFilter,
  setFilter as setUsersFilter,
} from 'store/features/users.slice';

export const useUsersFilter = () => {
  const dispatch = useAppDispatch();
  
  const filter = useAppSelector(selectUsersFilter);
  const setFilter = (filter: UserPaginationFilter) => {
    dispatch(setUsersFilter(filter));
  };
  const resetFilter = () => {
    dispatch(resetUsersFilter());
  };

  return useMemo(() => ({ filter, setFilter, resetFilter }), [filter]);
};
