import {alpha, Box, List, ListItem, ListItemText, useTheme} from "@mui/material";
import {MultipleFieldErrors} from "react-hook-form";
import {FormattedMessage} from "react-intl";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Done} from "@mui/icons-material";
import {flatten} from "flat";
import {useCallback} from "react";

type PasswordRequirementsProps = {
    touched?: boolean;
    errors?: MultipleFieldErrors;
}

export const PasswordRequirements = ({errors, touched}: PasswordRequirementsProps) => {
    const {palette, shape, typography} = useTheme();

    const flatErrors: any | undefined = !!errors ? flatten(errors, {safe: false}) : undefined;
    const formErrors: string[] | undefined = !!errors ? Object.values(flatErrors) : undefined;

    const allErrors: string[] = [
        'global.forms.errors.password.minLength',
        'global.forms.errors.password.maxLength',
        'global.forms.errors.password.numberRequired',
        'global.forms.errors.password.capitalLetterRequired',
        'global.forms.errors.password.symbolRequired'
    ];

    const isValidItem = useCallback((key: string) => {
        return touched && !formErrors?.includes(key);
    }, [formErrors])

    return (
        <Box sx={{
            background: alpha(palette.secondary.main, palette.action.activatedOpacity),
            p: 2,
            borderRadius: shape.borderRadius
        }}>

            <List dense>
                {allErrors.map((x, index) => {
                    return (
                        <ListItem key={`password-errors-${index}`} sx={{p: 0, m: 0}}>
                            <ListItemIcon>
                                {isValidItem(x) && (
                                    <Done sx={{width: 20, color: palette.success.main}}/>)}
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{
                                variant: 'caption',
                                fontWeight: isValidItem(x) ? typography.fontWeightBold : '',
                                color: isValidItem(x) ? palette.success.main : ''
                            }}
                                          primary={<FormattedMessage id={x}/>}/>
                        </ListItem>
                    )
                })}

            </List>
        </Box>
    )
}