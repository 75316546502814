import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, Tooltip, useTheme } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useState } from 'react';
import { MenuItem } from './index';

type MainMenuItemProps = Omit<ListItemProps, 'selected' | 'onClick'> & {
  item: MenuItem;
  collapsed: boolean;
  selected: boolean;
  onClick: (item: MenuItem) => void;
};

export const MainMenuItem = ({
  item,
  selected,
  collapsed,
  onClick,
  disablePadding = true,
  sx,
  ...other
}: MainMenuItemProps) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(!item.children);

  const handleClick = (event: any) => {
    if (!!item.children?.length) setIsCollapsed(!isCollapsed);

    onClick(item);
  };

  return (
    <ListItem disablePadding={disablePadding} sx={{ display: 'block', ...sx }} {...other}>
      <Tooltip title={item.title} arrow enterDelay={800} placement='right'>
        <ListItemButton
          selected={selected}
          sx={{
            opacity: 0.7,
            '&.Mui-selected': { opacity: 1 },
            '&:hover': { opacity: 1 },
            justifyContent: collapsed ? 'initial' : 'center',
            px: 2.5,
          }}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              color: theme.palette.getContrastText(theme.palette.primary.main),
              minWidth: 0,
              mr: collapsed ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {selected ? item.selectedIcon || item.icon : item.icon || <></>}
          </ListItemIcon>
          <ListItemText primary={item.title} sx={{ opacity: collapsed ? 1 : 0 }} />
          {!!item.children && collapsed && (isCollapsed ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </Tooltip>
      {!!item.children && (
        <Collapse in={isCollapsed} timeout='auto' unmountOnExit>
          <List>
            {item.children.map((i, index) => (
              <MainMenuItem
                key={`menu-child-item-${index}`}
                item={i}
                onClick={onClick}
                sx={{ paddingRight: 0 }}
                disablePadding={!collapsed}
                collapsed={isCollapsed}
                selected={selected}
                {...other}
              />
            ))}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};
