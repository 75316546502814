import { DeviceInfo } from '../models';
import { identityApi } from './http.service';
import { baseErrorResponseTransform } from './queries';
import { GetUserDevicesRequest, UnlinkUserDeviceRequest } from './requests';

const baseAdminUrl = 'administration/users';

const adminDevicesService = identityApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeviceByUserId: builder.query<DeviceInfo[], GetUserDevicesRequest>({
      query: ({ userId }) => ({
        url: `${baseAdminUrl}/${userId}/devices`,
        method: 'GET',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      providesTags: (result, error, arg) => [{ type: 'Devices', id: arg.userId }],
    }),
    revokeDeviceByUserId: builder.mutation<void, UnlinkUserDeviceRequest>({
      query: ({ userId, deviceCode }) => ({
        url: `${baseAdminUrl}/${userId}/revoke-token`,
        body: {
          deviceCode: deviceCode,
        },
        method: 'POST',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: (result, error, arg) => [{ type: 'Devices', id: arg.userId }],
    }),
  }),
});

export const { useGetDeviceByUserIdQuery, useRevokeDeviceByUserIdMutation, endpoints } =
  adminDevicesService;
