import {
  Button as ButtonComponent,
  ButtonProps as ButtonComponentProps,
  CircularProgress,
} from '@mui/material';

export type ButtonProps = ButtonComponentProps & {
  loading?: boolean;
};

export const Button = ({
  loading,
  disabled,
  children,
  startIcon,
  endIcon,
  ...other
}: ButtonProps) => {
  return (
    <ButtonComponent
      disabled={loading || disabled}
      startIcon={loading ? null : startIcon}
      endIcon={loading ? null : endIcon}
      {...other}
    >
      {loading && <CircularProgress size={25} />}
      {!loading && children}
    </ButtonComponent>
  );
};
