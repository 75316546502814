import {Breakpoint, CircularProgress, Container, Stack} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {GoBackButton, GoBackButtonProps} from 'components';
import React, {PropsWithChildren, ReactNode} from 'react';

type PageProps = {
    title?: string | React.ReactNode;
    loading?: boolean;
    actions?: ReactNode[];
    maxWidth?: Breakpoint;
    goBackProps?: GoBackButtonProps;
};

export const Page = ({
                         title,
                         loading,
                         actions,
                         children,
                         maxWidth,
                         goBackProps = {show: false},
                     }: PropsWithChildren<PageProps>) => {
    if (loading) {
        return (
            <Grid container justifyContent='center' alignContent='center' sx={{minHeight: '70vh'}}>
                <CircularProgress/>
            </Grid>
        );
    }
    return (
        <>
            <Grid
                container
                direction='row'
                alignContent='center'
                justifyContent='space-between'
                mb={5}
                columns={{xs: 2, sm: 4}}
            >
                <Grid item>
                    <Stack alignItems='center' spacing={2} direction='row'>
                        <GoBackButton {...goBackProps} />
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>
                            {title}
                        </Typography>
                    </Stack>
                </Grid>
                {actions?.map((x, index) => (
                    <Grid item key={`page-action-${index}`}>
                        {x}
                    </Grid>
                ))}
            </Grid>
            {!!maxWidth && <Container maxWidth={maxWidth}>{children}</Container>}
            {!maxWidth && children}
        </>
    );
};
