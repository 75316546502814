import { DesktopWindowsOutlined, Language, LinkOff } from '@mui/icons-material';
import { Chip, List, ListItem, ListItemAvatar, Stack, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { DeviceType } from 'enums';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { HeadsetIcon, NoItemsExists, OnlineBadge } from '../../../../components';
import { DeviceInfo } from '../../../../models';

type UserDevicesProps = {
  devices: DeviceInfo[];
  currentDeviceCode: string;
  onUnlinkClick?: (device: DeviceInfo) => void;
};
export const UserDevices = ({ devices, onUnlinkClick, currentDeviceCode }: UserDevicesProps) => {
  if (!devices.length)
    return (
      <NoItemsExists
        title={<FormattedMessage id={'global.user.labels.noDevices'} />}
        description={<FormattedMessage id={'global.user.labels.noDevicesDetailed'} />}
      />
    );

  return (
    <List>
      {devices.map((x, index) => {
        let icon: ReactElement;
        switch (x.deviceType) {
          case DeviceType.Web:
            icon = <Language color='primary' />;
            break;
          case DeviceType.Desktop:
            icon = <DesktopWindowsOutlined color='primary' />;
            break;
          case DeviceType.Headset:
            icon = <HeadsetIcon color='primary' />;
            break;
          default:
            icon = <></>;
            break;
        }

        return (
          <ListItem
            key={`user-device-${index}`}
            secondaryAction={
              currentDeviceCode !== x.deviceCode && (
                <Tooltip title={<FormattedMessage id={'global.user.tooltips.unlinkDevice'} />}>
                  <IconButton onClick={() => onUnlinkClick?.(x)} color='error'>
                    <LinkOff />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <ListItemButton sx={{ cursor: 'inherit' }} dense>
              <ListItemAvatar>{icon}</ListItemAvatar>
              <ListItemText
                primary={
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <div>{x.deviceType}</div>
                    {x.isActive && <OnlineBadge badgeContent=' ' variant='dot' />}
                    {currentDeviceCode === x.deviceCode && (
                      <Chip
                        variant='outlined'
                        size='small'
                        label={<FormattedMessage id={'user.labels.currentDevice'} />}
                      />
                    )}
                  </Stack>
                }
                secondary={
                  <FormattedDate
                    value={x.loginDate}
                    dateStyle='medium'
                    timeStyle='short'
                    children={(date) => {
                      return (
                        <FormattedMessage
                          id={'global.user.labels.loginDate'}
                          values={{ date: date }}
                        />
                      );
                    }}
                  />
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
