import { isRejectedWithValue } from '@reduxjs/toolkit';
import * as toast from '../../utils/notifications.util';

import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

export const ErrorNotificationMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.meta.baseQueryMeta.response.status === 500)
        toast.default.error(action.payload.message);
    }
    return next(action);
  };
