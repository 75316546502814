import { TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { defaultCountries, usePhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

type PhoneInputFieldProps = Omit<TextFieldProps, 'type' | 'onChange'> & {
  defaultCountry?: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
};

export const PhoneInputField = React.forwardRef<HTMLInputElement | null, PhoneInputFieldProps>(
  ({ onChange, value, defaultCountry, ...props }: PhoneInputFieldProps, ref) => {
    const { inputValue, handlePhoneValueChange, inputRef, setCountry } = usePhoneInput({
      defaultCountry: defaultCountry?.toLowerCase(),
      value: value?.toString(),
      disableDialCodePrefill: true,
      onChange: (data) => {
        onChange?.({ target: { name: props.name || 'phoneNumber', value: data.phone } });
      },
    });

    useEffect(() => {
      const iso = defaultCountry?.toLowerCase();
      const country = defaultCountries.find((x) => x[1] === iso)?.[1];
      if (country) setCountry(country);
    }, [defaultCountry]);

    return (
      <TextField
        {...props}
        ref={ref}
        inputRef={inputRef}
        type='tel'
        value={inputValue}
        onChange={handlePhoneValueChange}
      />
    );
  },
);
