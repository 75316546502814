import { Grid, Toolbar, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

type DataTableToolbarProps = {
  selectedCount: number;
  actions?: JSX.Element[];
};

export const DataTableToolbar = ({ selectedCount, actions }: DataTableToolbarProps) => {
  if (selectedCount <= 0) {
    return <></>;
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selectedCount > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
        <FormattedMessage
          id={'global.labels.selectedItemsCount'}
          values={{ count: selectedCount }}
        />
      </Typography>
      {actions?.map((x, index) => (
        <Grid item key={`table-action-${index}`}>
          {x}
        </Grid>
      ))}
    </Toolbar>
  );
};
