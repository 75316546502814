import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import * as storageService from '../../services/storage.service';
import { APP_STATE } from 'utils/constants';

interface AppState {
  menuCollapsed: boolean;
}

const initialState: AppState = storageService.get<AppState>(APP_STATE) || {
  menuCollapsed: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    toggleMenu: (state) => {
      state.menuCollapsed = !state.menuCollapsed;
      storageService.set(APP_STATE, state);

      return state;
    },
  },
});

export const { toggleMenu } = appSlice.actions;
export default appSlice.reducer;
export const selectAppState = (state: RootState) => state.app;
