import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteParams } from '../../../constants';
import { DownloadApp } from '../components';
import { CompleteInviteForm } from '../components/CompleteInviteForm';

export const CompleteInvitePage = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const [success, setSuccess] = useState(false);

  const code = queryParams.get(RouteParams.invitationCode) || '';
  const onSuccessAction = queryParams.get(RouteParams.onCompleteAccount) || '';

  const onSuccess = () => {
    if (onSuccessAction === 'download-app') setSuccess(true);
    else if (onSuccessAction) navigate('/' + onSuccessAction);
    else navigate('/');
  };

  if (success) return <DownloadApp />;

  return <CompleteInviteForm code={code} onSuccess={onSuccess} />;
};
