import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface DropdownMenuType {
  title: string | number;
  menuItems: {
    title: string | number;
    action: (value?: string | string[]) => void;
  }[];
  isDisabled?: boolean;
}

export const DropdownMenu = (prop: DropdownMenuType) => {
  const { menuItems, title, isDisabled = false } = prop;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        endIcon={<Icon isOpen={open} />}
        size='large'
        color='inherit'
        variant='text'
        disabled={isDisabled}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.title}
            onClick={() => {
              menuItem.action();
              handleClose();
            }}
          >
            {menuItem.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const Icon = ({ isOpen }: { isOpen: boolean }) => {
  return isOpen ? <ExpandLess /> : <ExpandMore />;
};
