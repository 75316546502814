import {MenuItem} from "./index";
import {Analytics, AnalyticsOutlined, EventNote, EventNoteOutlined, People, PeopleOutline} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import {adminRouteNames} from "portals/main/management";

export const adminMenuItems: MenuItem[] = [
    {
        key: `${adminRouteNames.management.root}/${adminRouteNames.management.users.root}`,
        title: <FormattedMessage id={'admin.pages.users'}/>,
        icon: <PeopleOutline/>,
        selectedIcon: <People/>
    },
    {
        key: adminRouteNames.analytics.root,
        title: <FormattedMessage id={'admin.pages.analytics'}/>,
        icon: <AnalyticsOutlined/>,
        selectedIcon: <Analytics/>,
        children: [
            {
                key: `${adminRouteNames.analytics.root}/${adminRouteNames.analytics.auditLogs}`,
                title: <FormattedMessage id={'admin.pages.auditLogs'}/>,
                icon: <EventNoteOutlined/>,
                selectedIcon: <EventNote/>,
            }
        ]
    }
];