import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { identityRouteNames } from '../../portals';

type RequiredQueryParamGuardProps = {
  paramName: string;
};

export const RequiredQueryParamGuard = ({ paramName }: RequiredQueryParamGuardProps) => {
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const param = queryParams.get(paramName);

  if (param) {
    return <Outlet />;
  }

  return <Navigate to={'/' + identityRouteNames.login} state={{ from: location }} />;
};
