import {createTheme} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import {PaletteMode} from '@mui/material';
import {darkPalette} from './darkTheme';
import {lightPalette} from './lightTheme';

const getOffset = (height: number) => (56 - height) / 2;

const getDesignTokens = (mode: PaletteMode) => (mode === 'dark' ? darkPalette : lightPalette);

export const getTheme = (mode: PaletteMode) =>
    createTheme({
        palette: getDesignTokens(mode),
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: ({theme, ownerState}) => ({
                        textTransform: 'capitalize',
                        
                        '&.Mui-selected.MuiToggleButton-primary': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.getContrastText(theme.palette.primary.main),
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                            }
                        }
                    }),
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: ({theme, mode}) => {
                        return {
                            background: theme.palette.mode === 'light' ? theme.palette.primary.main : '',
                            color: theme.palette.getContrastText(theme.palette.primary.dark),
                            borderRight: 'none',
                        };
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: ({theme}) => ({
                        background: theme.palette.primary.main,
                    }),
                },
                defaultProps: {
                    elevation: 1,
                },
            },
            MuiFormControl: {
                variants: [
                    {
                        props: {size: 'medium'},
                        style: () => {
                            return {
                                root: {
                                    height: '48px',
                                },
                            };
                        },
                    },
                ],
            },
            MuiInputBase: {
                variants: [
                    {
                        props: {size: 'medium'},
                        style: () => {
                            return {
                                root: {
                                    height: '48px',
                                },
                            };
                        },
                    },
                ],
            },
            MuiTextField: {
                defaultProps: {
                    size: 'medium',
                    FormHelperTextProps: {
                        sx: {marginTop: 0},
                    },
                },
                styleOverrides: {
                    root: ({theme}) => {
                        return {
                            '.Mui-focused': {
                                borderWidth: '2px',

                                '& > .MuiOutlinedInput-notchedOutline': {
                                    border: `1px solid  ${theme.palette.secondary.dark}`,
                                    boxShadow: `1px 1px 3px 0px ${theme.palette.secondary.main}, -1px -1px 3px 0px ${theme.palette.secondary.main}`,

                                    '&  span': {
                                        paddingRight: 0,
                                    },
                                },
                            },
                            '.MuiOutlinedInput-root:hover > .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.secondary.dark,
                            },
                            '>label': {
                                '&.Mui-focused': {
                                    background: theme.palette.background.paper,
                                    minWidth: '50px',
                                    outline: 'none',
                                    left: '-7px',
                                    fontSize: '16px',
                                    padding: '0 7px 0 8px',
                                },
                            },
                        }
                    }
                },
                variants: [
                    {
                        props: {error: true},
                        style: ({theme}) => {
                            return {
                                '.Mui-focused > .MuiOutlinedInput-notchedOutline': {
                                    border: `1px solid  ${theme.palette.error.main}`,
                                    boxShadow: `1px 1px 3px 0px ${theme.palette.error.main}, -1px -1px 3px 0px ${theme.palette.error.main}`,
                                },
                                '.MuiOutlinedInput-root:hover > .MuiOutlinedInput-notchedOutline': {
                                    border: `1px solid  ${theme.palette.error.main}`,
                                },
                            };
                        },
                    },
                    {
                        props: {size: 'medium'},
                        style: ({theme}) => {
                            return {
                                input: {
                                    paddingTop: `calc(18.5px - ${getOffset(48)}px)`,
                                    paddingBottom: `calc(18.5px - ${getOffset(48)}px)`,
                                },

                                label: {
                                    top: `-${getOffset(50)}px`,
                                },
                            };
                        },
                    },
                ],
            },
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: {
                        color: 'red',
                    },
                },
            },
            MuiTable: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: ({theme}) => ({
                        background: theme.palette.background.paper,
                    }),
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: ({theme}) => ({
                        background: theme.palette.background.paper,
                        color: grey[500],
                    }),
                },
            },
        },
        typography:
            {
                fontFamily: `"Inter", sans-serif`,
            }
        ,
    })
;
