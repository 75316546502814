import {
    Box,
    Checkbox, TableCell, TableHead,
    TableRow,
    TableSortLabel
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import React from "react";
import { AnyObject, Column } from "./types";

export type Order = 'asc' | 'desc';

export type DataTableHeaderProps<RecordType = unknown> = {
    selectable?: boolean;
    columns: Column<RecordType>[];
    selectedCount?: number;
    order?: Order;
    orderBy?: string;
    onSortChanged?: (order?: Order, orderBy?: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowsCount: number;
}

export const DataTableHeader = <RecordType extends AnyObject = AnyObject>({
    selectable = false,
    selectedCount = 0,
    onSelectAllClick,
    columns,
    rowsCount,
    order,
    orderBy,
    onSortChanged
}: DataTableHeaderProps<RecordType>) => {

    const handleSort = (column: Column<RecordType>) => {
        const newOrder = column.key !== orderBy ? "asc" : (order || 'asc') === "asc" ? 'desc' : 'asc';

        onSortChanged?.(newOrder, column.key.toString());
    };

    return (
        <TableHead>
            <TableRow>
                {selectable && (<TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={selectedCount > 0 && selectedCount < rowsCount}
                        checked={rowsCount > 0 && selectedCount === rowsCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all items',
                        }} />
                </TableCell>)}
                {columns.map((column) => (
                    <TableCell key={column.key}
                        align={column.align}
                        sortDirection={orderBy === column.key ? order : 'asc'}
                        style={{ minWidth: column.minWidth, width: column.width }}>
                        {!column.sortable && (column.title)}
                        {!!column.sortable && (

                            <TableSortLabel
                                hideSortIcon={false}
                                active={orderBy === column.key}
                                direction={orderBy === column.key ? order : 'asc'}
                                onClick={() => handleSort(column)}>
                                {column.title}
                                {orderBy === column.key ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead >
    )
        ;
};
