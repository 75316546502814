import { AuthorisationData } from '../models';
import { identityApi } from './http.service';
import { baseErrorResponseTransform } from './queries';
import {
  SingInRequest,
  ChangePasswordRequest,
  CompleteInvitationRequest,
  CompleteResetPasswordRequest,
  RequestResetPasswordRequest,
  VerifyEmailRequest,
} from './requests';

const identityService = identityApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthorisationData, SingInRequest>({
      query: (authData) => ({ url: 'identity/signIn', method: 'POST', body: authData }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: ['Identity', { type: 'Users', id: 'me' }],
    }),
    // signUp: builder.mutation<void, SignUpRequest>({
    //     query: (signUpData) => ({url: 'identity/signUp', method: 'POST', body: signUpData}),
    //     invalidatesTags: ['Identity'],
    //     transformErrorResponse: baseErrorResponseTransform,
    //
    // }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'identity/logout',
        method: 'POST',
        boyd: {},
        transformErrorResponse: baseErrorResponseTransform,
      }),
    }),
    resetPassword: builder.mutation<void, RequestResetPasswordRequest>({
      query: ({ email }) => ({
        url: `identity/reset-password?email=${email}`,
        method: 'GET',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: ['Identity'],
    }),
    verifyEmail: builder.mutation<void, VerifyEmailRequest>({
      query: ({ code }) => ({
        url: `identity/verify-email?code=${code}`,
        method: 'GET',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: ['Identity'],
    }),
    completeResetPassword: builder.mutation<void, CompleteResetPasswordRequest>({
      query: (data) => ({
        url: 'identity/reset-password',
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: ['Identity'],
    }),
    completeInvitation: builder.mutation<void, CompleteInvitationRequest>({
      query: (data) => ({
        url: 'identity/complete-invite',
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: ['Identity'],
    }),
  }),
});

export const {
  useSignInMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useVerifyEmailMutation,
  useCompleteResetPasswordMutation,
  useCompleteInvitationMutation,
  endpoints,
} = identityService;
