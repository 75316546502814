import {Box, Button, Stack} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {isRouteErrorResponse, useNavigate, useRouteError} from 'react-router-dom';
import {ReactComponent as ForbiddenSvg} from '../../assets/403_page.svg';
import {ReactComponent as NotFoundSvg} from '../../assets/404_page_v2.svg';
import {ReactComponent as ServerErrorSvg} from '../../assets/500_page.svg';
import {ReactComponent as ServiceUnavailableSvg} from '../../assets/503_page.svg';

export const ErrorPage = () => {
    const navigate = useNavigate();
    const error = useRouteError();
    const goToMain = () => navigate('/');

    const getErrorImage = (height: number) => {
        if (isRouteErrorResponse(error))
            switch (error.status) {
                case 403:
                    return <ForbiddenSvg height={height}/>;
                case 404:
                    return <NotFoundSvg height={height}/>;
                case 500:
                    return <ServerErrorSvg height={height}/>;
                case 503:
                    return <ServiceUnavailableSvg height={height}/>;
                default:
                    return <ServerErrorSvg height={height}/>;
            }
    };

    return (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
            <Stack alignItems='center'>
                {getErrorImage(530)}
                <Button size='large' variant='outlined' color='secondary' fullWidth onClick={goToMain}>
                    <FormattedMessage id='global.buttons.goToMain'/>
                </Button>
            </Stack>
        </Box>
    );
};
