import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { flatten } from 'flat';
import { DialogsProvider } from 'providers/DialogsProvider';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { DEVICE_CODE_KEY } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { useIsDarkTheme } from './hooks/useIsDarkTheme';
import en from './lang/en.json';
import { AppNotifications, SignalRNotifications } from './providers';
import { AppRouter } from './routes';
import * as storageService from './services/storage.service';
import { useAppDispatch } from './store';
import { initDeviceSession } from './store/features/device.slice';
import { getTheme } from './themes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const deviceCode = storageService.get<string>(DEVICE_CODE_KEY);

function App() {
  const dispatch = useAppDispatch();
  const isDarkTheme = useIsDarkTheme();
  useEffect(() => {
    dispatch(initDeviceSession(deviceCode || uuidv4()));
  }, []);

  return (
    <ThemeProvider theme={getTheme(isDarkTheme ? 'dark' : 'light')}>
      <CssBaseline />
      <IntlProvider messages={flatten(en)} locale='en' defaultLocale='en'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppNotifications>
            {/* <SignalRNotifications> */}
            <DialogsProvider>
              <AppRouter />
            </DialogsProvider>
            {/* </SignalRNotifications> */}
          </AppNotifications>
        </LocalizationProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
