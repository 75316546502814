import {zodResolver} from '@hookform/resolvers/zod';
import {CompleteInvitationData} from 'models';
import {CompleteInvitationSchema} from 'models/schemas';
import {SubmitHandler, useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {PasswordField, getFormErrorMessage, PasswordRequirements} from '../../../components';
import apiServices from '../../../services';
import {ApiErrorResponse} from '../../../services/responses';
import {setBackendError} from '../../../utils';
import {IdentityForm} from './IdentityForm';
import {Alert} from '@mui/material';
import {useState} from 'react';

type CompleteInviteFormProps = {
    code: string;
    onSuccess?: () => void;
};

export const CompleteInviteForm = ({code, onSuccess}: CompleteInviteFormProps) => {
    const {formatMessage} = useIntl();
    const [apiError, setApiError] = useState<string>();
    const {
        register,
        setError,
        handleSubmit,
        trigger,
        formState: {errors, dirtyFields},
    } = useForm<CompleteInvitationData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        resolver: zodResolver(CompleteInvitationSchema),
    });
    const [completeInvite, {isLoading}] = apiServices.identity.useCompleteInvitationMutation();

    const onSubmit: SubmitHandler<CompleteInvitationData> = (data) => {
        completeInvite({
            ...data,
            code: code,
        })
            .unwrap()
            .then(() => {
                onSuccess?.();
            })
            .catch((e: ApiErrorResponse) => {
                setBackendError(setError, e);
                if (e.detailedMessages) setApiError(e.detailedMessages[0]);
            });
    };

    return (
        <IdentityForm
            loading={isLoading}
            title={<FormattedMessage id={'identity.completeInvitePage.title'}/>}
            submitText={<FormattedMessage id={'identity.completeInvitePage.buttons.changePassword'}/>}
            onSubmit={handleSubmit(onSubmit)}
        >
            {!!apiError && <Alert severity='error'>{apiError}</Alert>}
            <PasswordField
                error={!!errors.newPassword}
                helperText={getFormErrorMessage(errors.newPassword?.message)}
                autoComplete='new-password'
                label={formatMessage({id: 'identity.completeInvitePage.labels.newPassword'})}
                {...register('newPassword', {
                    onChange: async (e) => {
                        if (e.target.value && dirtyFields.confirmPassword) {
                            await trigger(['confirmPassword'])
                        }
                    },
                })}
            />

            <PasswordField
                error={!!errors.confirmPassword}
                helperText={getFormErrorMessage(errors.confirmPassword?.message)}
                autoComplete='new-password'
                label={formatMessage({id: 'identity.completeInvitePage.labels.confirmPassword'})}
                {...register('confirmPassword', {
                    onChange: async (e) => {
                        if (e.target.value && dirtyFields.newPassword) {
                            await trigger(['newPassword'])
                        }
                    },
                })}
            />
            <PasswordRequirements errors={errors.newPassword?.types} touched={dirtyFields.newPassword}/>

        </IdentityForm>
    );
};
