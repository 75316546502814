import {IconButton, Tooltip} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {FilterAltOff} from "@mui/icons-material";
import React from "react";

export const ClearFiltersButton = ({onClick}: { onClick: () => void }) => {
    return (
        <Tooltip title={<FormattedMessage id='global.tooltips.clearFilters'/>}>
            <IconButton color='error' onClick={onClick}>
                <FilterAltOff/>
            </IconButton>
        </Tooltip>
    );
};