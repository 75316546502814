import { DeviceInfo } from '../models';
import { identityApi } from './http.service';
import { baseErrorResponseTransform } from './queries';
import { UnlinkMyDeviceRequest } from './requests';

const baseUrl = 'users/me/devices';

const meDevicesService = identityApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyDevices: builder.query<DeviceInfo[], void>({
      query: () => ({
        url: baseUrl,
        method: 'GET',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      providesTags: [{ type: 'Devices', id: 'mine' }],
    }),

    unlinkMyDevice: builder.mutation<void, UnlinkMyDeviceRequest>({
      query: ({ deviceCode }) => ({
        url: `${baseUrl}/${deviceCode}`,
        method: 'DELETE',
      }),
      transformErrorResponse: baseErrorResponseTransform,
      invalidatesTags: [{ type: 'Devices', id: 'mine' }],
    }),
  }),
});

export const { useGetMyDevicesQuery, useUnlinkMyDeviceMutation, endpoints, util } =
  meDevicesService;
