import { createSlice } from '@reduxjs/toolkit';
import apiServices from 'services';
import { UserInfo } from '../../models';
import * as storageService from '../../services/storage.service';
import { ACCESS_TOKEN_KEY, USER_INFO_KEY } from '../../utils/constants';
import { RootState } from '../store';

interface AuthState {
  user: UserInfo | null;
  token: string | null;
  isAuthorized: boolean;
}

const initialState: AuthState = {
  user: storageService.get<UserInfo>(USER_INFO_KEY) || null,
  token: storageService.get<string>(ACCESS_TOKEN_KEY) || null,
  isAuthorized: !!storageService.get<string>(ACCESS_TOKEN_KEY),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      console.log('User logout');

      storageService.remove(ACCESS_TOKEN_KEY);
      storageService.remove(USER_INFO_KEY);

      state = { user: null, token: null, isAuthorized: false };

      return state;
    },
    tokenReceived: (state, { payload }) => {
      state.isAuthorized = true;
      state.token = payload.accessToken;

      storageService.set(ACCESS_TOKEN_KEY, state.token);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(apiServices.identity.endpoints.logout.matchFulfilled, (state, action) => {
        state = authSlice.caseReducers.logout(state);

        return state;
      })
      .addMatcher(apiServices.identity.endpoints.signIn.matchFulfilled, (state, action) => {
        state.isAuthorized = true;
        state.token = action.payload.accessToken;

        storageService.set(ACCESS_TOKEN_KEY, state.token);
      })
      .addMatcher(apiServices.me.users.endpoints.getMyUser.matchFulfilled, (state, action) => {
        state.user = action.payload;
        storageService.set(USER_INFO_KEY, state.user);
      });
  },
});

export const { logout, tokenReceived } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.auth;
