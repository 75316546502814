import {Avatar, Box, Button, Menu, MenuItem, useTheme} from '@mui/material';
import {UserInfo} from '../../../../../models';
import Typography from '@mui/material/Typography';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {api, identityApi} from '../../../../../services/http.service';
import {identityRouteNames} from '../../../../identity';
import apiServices from 'services';

import {useAppDispatch} from '../../../../../store';
import {useNavigate} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ExpandLess, ExpandMore, Logout, Settings} from '@mui/icons-material';
import {getRoute} from 'utils';
import {userRouteNames} from 'portals/main/user';

function stringAvatar(firstName: string, lastName: string) {
    return {
        sx: {
            width: 46,
            height: 46,
            backgroundColor: 'primary.light',
            fontSize: '1rem',
        },
        children: `${firstName[0]}${lastName[0]}`,
    };
}

type UserProfileProps = {
    user: UserInfo;
};

export const UserProfile = ({user}: UserProfileProps) => {
    const {palette} = useTheme()
    const [logout] = apiServices.identity.useLogoutMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const rotate = anchorEl ? "rotate(180deg)" : "rotate(0)"
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettingsClick = () => {
        navigate(getRoute(userRouteNames.profile.root, userRouteNames.profile.view));
        handleClose();
    };

    const handleLogout = () => {
        logout().then(() => {
            dispatch(api.util.resetApiState());
            dispatch(identityApi.util.resetApiState());

            handleClose();
            navigate('/' + identityRouteNames.login);
        });
    };

    return (
        <>
            <Button variant='contained' disableElevation
                    onClick={handleMenu}
                    sx={{
                        ':hover': {
                            bgcolor: palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255,255,255,0.04)',
                            color: 'white',
                        },
                    }}
                    startIcon={<Avatar {...stringAvatar(user.firstName, user.lastName)} />}
                    endIcon={<ExpandMore style={{transform: rotate, transition: "all 0.2s linear"}}/>}>
                <Box textAlign='left'>
                    <Typography variant='body1'>
                        {`${user.firstName} ${user.lastName}`}
                    </Typography>
                    <Typography textTransform='lowercase' variant='caption' sx={{opacity: 0.7}}>
                        {user.email}
                    </Typography>
                </Box>
            </Button>
            <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 2,
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 15,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={handleSettingsClick}>
                    <ListItemIcon>
                        <Settings fontSize='small'/>
                    </ListItemIcon>
                    <ListItemText>
                        <FormattedMessage id={'global.buttons.settings'}/>
                    </ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize='small' color='error'/>
                    </ListItemIcon>
                    <ListItemText color='error'>
                        <FormattedMessage id={'global.buttons.logout'}/>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};
