import {Page, UserDevices} from '../../shared';
import apiServices from '../../../../services';
import {Card, CardContent} from '@mui/material';
import {useDialogs} from '../../../../providers';
import {FormattedMessage, useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {DeviceInfo} from '../../../../models';
import {useAppSelector} from '../../../../store';
import {selectCurrentDeviceSession} from '../../../../store/features/device.slice';
import {useCurrentUser} from '../../../../hooks';
import {ApiErrorResponse} from '../../../../services/responses';
import {useSnackbar} from 'notistack';
import {NoItemsExists} from "../../../../components";
import React, {useMemo} from "react";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export const UserDevicesPage = () => {
    const {dialog} = useDialogs();
    const {formatMessage} = useIntl();
    const {userId} = useParams();
    const {user: currentUser} = useCurrentUser();
    const {enqueueSnackbar} = useSnackbar();

    const {data, isLoading, error} = apiServices.admin.devices.useGetDeviceByUserIdQuery({
        userId: userId || '',
    });
    const [revokeDevice, {isLoading: isRevoking}] =
        apiServices.admin.devices.useRevokeDeviceByUserIdMutation();
    const {deviceId} = useAppSelector(selectCurrentDeviceSession);

    const userNotFound = useMemo(() => {
        return (error as FetchBaseQueryError)?.status === 404;
    }, [error]);

    const handleUnlinkClick = (device: DeviceInfo) => {
        dialog.confirm({
            title: <FormattedMessage id={'global.user.text.confirmUnlinkDeviceTitle'}/>,
            message: <FormattedMessage id={'global.user.text.confirmUnlinkDevice'}/>,
            onOk: async () => {
                await revokeDevice({userId: userId!, deviceCode: device.deviceCode})
                    .unwrap()
                    .then(() =>
                        enqueueSnackbar(formatMessage({id: 'global.user.notifications.deviceUnlinked'}), {
                            variant: 'success',
                        }),
                    )
                    .catch((e: ApiErrorResponse) => {
                        enqueueSnackbar(e.error, {variant: 'error'});
                    });
            },
            okButtonProps: {color: 'error', loading: isRevoking},
        });
    };

    return (
        <Page
            goBackProps={{show: true, destination: 'back'}}
            maxWidth={'sm'}
            loading={isLoading}
            title={formatMessage({id: 'user.pages.devices'})}
        >
            {userNotFound && (<NoItemsExists title={<FormattedMessage id='admin.userPage.labels.userNotFound'/>}
                                             description={<FormattedMessage
                                                 id='admin.userPage.labels.userDetailsNotFound'/>}/>)}
            {!userNotFound && !!data && (
                <Card>
                    <CardContent>
                        <UserDevices
                            currentDeviceCode={currentUser?.id === userId ? deviceId : ''}
                            devices={data}
                            onUnlinkClick={handleUnlinkClick}
                        />
                    </CardContent>
                </Card>
            )}
        </Page>
    );
};
