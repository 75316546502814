import { Stack, StackProps } from '@mui/material';
import { FormEventHandler, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

type FormProps = StackProps & {
  formName?: string;
  onSubmit?: FormEventHandler<any> | undefined;
};

export const getFormErrorMessage = (key?: string) => (!!key ? <FormattedMessage id={key} /> : null);

export const Form = ({ formName, onSubmit, children, ...props }: PropsWithChildren<FormProps>) => {
  return (
    <form noValidate id={formName} onSubmit={onSubmit}>
      <Stack spacing={4} {...props}>
        {children}
      </Stack>
    </form>
  );
};
