import {Page} from "../../../shared";
import {FormattedMessage} from "react-intl";
import {useFilterAuditLogsQuery} from "../../../../../services/admin.auditLogs.service";
import {useAppSelector} from "../../../../../store";
import {selectAuditLogsFilter, setFilter} from "../../../../../store/features/auditLogs.slice";
import {AuditLogsDataTable, AuditLogsDataTableToolbar} from "./components";
import {useDispatch} from "react-redux";
import {AuditType} from "../../../../../enums";

export const AuditLogsPage = () => {
    const dispatch = useDispatch();
    const filter = useAppSelector(selectAuditLogsFilter);
    const {data, isLoading, refetch, isFetching} = useFilterAuditLogsQuery(filter);

    const handlePageChange = (newPage: number) => {
        dispatch(setFilter({...filter, page: newPage}));
    };

    const handlePageSizeChange = (newPageSize: number) => {
        dispatch(setFilter({...filter, pageSize: newPageSize}));
    };

    const handleAuditTypeChange = (value?: AuditType) => {
        dispatch(setFilter({...filter, page: 1, auditType: value}));
    };
    return (
        <Page title={<FormattedMessage id='admin.pages.auditLogs'/>}>
            <AuditLogsDataTableToolbar onAuditTypeChange={handleAuditTypeChange}
                                       filter={{
                                           auditType: filter.auditType || '-1',
                                           entity: filter.entity || 'm'
                                       }}
                                       onRefresh={() => refetch()}/>
            <AuditLogsDataTable items={data?.items || []}
                                totalCount={data?.totalCount || 0}
                                filteredCount={data?.filteredCount || 0}
                                loading={isLoading || isFetching}
                                page={filter.page}
                                pageSize={filter.pageSize}
                                onPageChange={handlePageChange}
                                onPageSizeChange={handlePageSizeChange}/>
        </Page>
    )
}