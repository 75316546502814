import apiServices from '../../../../../services';

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../../../../components/modals';
import { ChangeUserInfoData, UserInfo } from '../../../../../models';
import { useSnackbar } from 'notistack';
import { UserForm, ZoomTransition } from 'portals/main/shared';
import { ApiErrorResponse } from '../../../../../services/responses';
import { setBackendError } from '../../../../../utils';
import { UseFormSetError } from 'react-hook-form';

export const AddNewUserGlobalButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [createInactiveUser, { isLoading: isCreating }] =
    apiServices.admin.users.useCreateInactiveUserMutation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const openAddModal = () => setIsOpenModal(true);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const handleForm = (user: ChangeUserInfoData, setError: UseFormSetError<ChangeUserInfoData>) => {
    createInactiveUser({...user})
      .unwrap()
      .then(() => {
        enqueueSnackbar(
          <FormattedMessage
            id={'admin.userPage.notifications.userCreated'}
            values={{
              firstName: user.firstName,
              lastName: user.lastName,
            }}
          />,
          { variant: 'success' },
        );

        closeModal();
      })
      .catch((e: ApiErrorResponse) => {
        setBackendError(setError, e);
      });
  };

  return (
    <>
      <Button variant='contained' size='large' onClick={openAddModal} startIcon={<PersonAdd />}>
        <FormattedMessage id={'admin.userPage.buttons.addUser'} />
      </Button>
      <Modal
        open={isOpenModal}
        TransitionComponent={ZoomTransition}
        onCancel={closeModal}
        title={<FormattedMessage id={'admin.userPage.labels.addingUser'} />}
        PaperProps={{
          component: 'div',
        }}
        okButtonProps={{ loading: isCreating, type: 'submit', form: 'creatingUser' }}
        cancelButtonProps={{ type: 'reset', form: 'creatingUser' }}
      >
        <UserForm user={null} onSubmit={handleForm} />
      </Modal>
    </>
  );
};
